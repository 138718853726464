import { Component } from '@angular/core';
import { SvgIconComponent } from "../../../common/components/svg-icon/svg-icon.component";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { ButtonComponent } from "../../../common/components/button/button.component";

@Component({
  selector: 'app-auth-footer',
  templateUrl: './auth-footer.component.html',
  styleUrls: ['./auth-footer.component.scss'],
})
export class AuthFooterComponent {}

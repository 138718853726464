<mat-label
  class="block text-sm leading-sm text-gray-700 font-medium pb-1.5"
  [ngClass]="{
    '!text-error-500': error
  }"
>
  {{ label }}
  @if (required) {
  *
  }
</mat-label>

<ng-content></ng-content>

@if (error) {
  <mat-error [@transitionMessages]="error ? 'enter' : ''" class="block text-xs leading-xs font-medium pt-2">
    {{ error }}
  </mat-error>
}

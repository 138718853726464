<app-dialog-header icon="mail-01" (dialogClose)="_matDialogRef.close()"/>

<app-dialog-body>
  <ng-template #heading>Check your your inbox</ng-template>
  <ng-template #description>
    We sent the confirmation link to your email address. If you didn't receive the email make sure to also check your
    spam folder.
  </ng-template>

</app-dialog-body>

<app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_LG">
  <h1 class="text-3xl leading-3xl font-medium text-gray-900 pb-1">Invoice {{ invoice ? invoice.number : "" }}</h1>
</app-skeleton-loader>

<app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_LG">
  <p class="text-sm leading-sm text-gray-500 mb-4">Invoiced on: {{ invoice ? (invoice.created * 1000 | date) : "" }}</p>
</app-skeleton-loader>

<app-skeleton-loader
  [loading]="loading"
  [rounding]="SkeletonLoaderRounding.ROUNDED_LG"
  [ngClass]="{
    'block rounded-lg overflow-hidden': loading
  }"
>
  <table mat-table [dataSource]="dataLines" matSort>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let dataLine">
        <div class="text-sm leading-sm font-medium text-gray-900">
          {{ dataLine.price.nickname }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Quantity</th>
      <td mat-cell *matCellDef="let dataLine">
        <div class="text-sm leading-sm text-gray-900">
          {{ dataLine.quantity | calcTime }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
      <td mat-cell *matCellDef="let dataLine">
        <div class="text-sm leading-sm text-gray-900 font-mono">
          {{ dataLine.currency | uppercase }}
          {{ dataLine.price.unit_amount_decimal / 100 | currency : "" : "" : "1.4-4" }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
      <td mat-cell *matCellDef="let dataLine">
        <div class="text-sm leading-sm text-gray-900 font-mono">
          {{ dataLine.currency | uppercase }}
          {{ dataLine.amount / 100 | currency : "" : "" }}
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
  </table>

  @if (loading) {
    <div class="h-[4.5rem]"></div>
    <div class="h-[4.5rem]"></div>
    <div class="h-[4.5rem]"></div>
    <div class="h-[4.5rem]"></div>
    <div class="h-[4.5rem]"></div>
    <div class="h-[4.5rem]"></div>
  }
</app-skeleton-loader>

import { Component, EventEmitter, inject, OnInit, Output, ViewChild } from "@angular/core";
import { AccountService } from 'src/app/common/services/account.service';
import { CountriesService, Country } from 'src/app/common/services/countries.service';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import { AddressSettingsComponent, AddressSettingsFormValue } from '../address-settings/address-settings.component';

@Component({
  selector: 'app-billing-address-step',
  templateUrl: './billing-address-step.component.html',
  styleUrls: ['./billing-address-step.component.scss'],
})
export class BillingAddressStepComponent implements OnInit {
  @Output() stepComplete = new EventEmitter<void>();

  // Used to trigger invalidity indication from the outside using `requestSubmit`
  // See `add-payment-wizard-dialog.component.ts`
  @ViewChild(AddressSettingsComponent) addressSettingsComponent: AddressSettingsComponent;

  private _accountService = inject(AccountService);
  private _spinnerService = inject(SpinnerService);
  private _countriesService = inject(CountriesService);

  countries: Country[] = [];
  loading: boolean = false;

  ngOnInit(): void {
    this.loading = true;

    this._countriesService.getCountries().subscribe({
      next: (countries) => {
        this.countries = countries;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  onSubmit(formValue: AddressSettingsFormValue): void {
    if (this._spinnerService.visible) {
      return;
    }

    this._spinnerService.show();

    const { street, city, postalCode, state, country } = formValue;

    const address = {
      street,
      city,
      postalCode,
      state,
      country,
    };

    this._accountService.updateOrganization(address).subscribe({
      next: () => {
        this._spinnerService.hide();
        this.stepComplete.emit();
        this.addressSettingsComponent.initialFormValue = {
          street,
          city,
          postalCode,
          state,
          country,
        };
        this.addressSettingsComponent.onReset();
      },
      error: () => {
        this._spinnerService.hide();
      },
    });
  }
}

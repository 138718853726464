<app-dialog-header icon="user-plus-02" (dialogClose)="onCancel()"/>

<app-dialog-body>
  <ng-template #heading>
    Add a member to
    <span class="text-primary-600">
      {{ organizationName }}
    </span>
  </ng-template>
  <ng-template #description>Members of this company will have access to all clusters within it.</ng-template>

  <form [formGroup]="formGroup" (submit)="onSubmit()" class="flex flex-col gap-2">
    <app-label [required]="true" [control]="formGroup.controls.fullName" title="Full name" #fullNameLabel/>
    <mat-form-field subscriptSizing="dynamic">
      <input (focusin)="fullNameLabel.focus()" (focusout)="fullNameLabel.focusout()" matInput placeholder="Full name" name="fullName" type="text" formControlName="fullName" class="relative z-[1]" />

      @if (formGroup.controls.fullName | hasError:'required') {
        <mat-error>Full name is required</mat-error>
      }
    </mat-form-field>

    <app-label [required]="true" [control]="formGroup.controls.email" title="Email" #emailLabel/>
    <mat-form-field subscriptSizing="dynamic">
      <app-svg-icon name="mail-01" matPrefix/>

      <input (focusin)="emailLabel.focus()" (focusout)="emailLabel.focusout()" matInput placeholder="Email" name="email" type="email" formControlName="email" class="relative z-[1]" />

      @if (formGroup.controls.email | hasError:'required') {
        <mat-error>Email is required</mat-error>
      }

      @if (formGroup.controls.email | hasError:'email') {
        <mat-error>Please enter a valid email address</mat-error>
      }
    </mat-form-field>

    <div class="pt-4 flex gap-3">
      <app-button
        [buttonType]="ButtonType.BUTTON"
        [buttonStyle]="ButtonStyle.BTN_1"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        (buttonClick)="onCancel()"
        [fluid]="true"
        class="flex-1"
      >
        Cancel
      </app-button>

      <app-button
        [buttonType]="ButtonType.SUBMIT"
        [buttonStyle]="ButtonStyle.BTN_0"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        [loading]="loading"
        [fluid]="true"
        class="flex-1"
      >
        Add member
      </app-button>
    </div>
  </form>
</app-dialog-body>

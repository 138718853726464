import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { confirmPasswordValidator } from 'src/app/common/helpers/validators';
import { User } from 'src/app/common/models/user';
import { AccountService } from 'src/app/common/services/account.service';
import {
  ButtonComponent,
  ButtonPadding,
  ButtonStyle,
  ButtonType
} from "src/app/common/components/button/button.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatError, MatFormField, MatFormFieldModule, MatHint } from "@angular/material/form-field";
import { PasswordVisibilityComponent } from "../../components/password-visibility/password-visibility.component";
import { LabelComponent } from "../../components/label/label.component";
import { SvgIconComponent } from "../../components/svg-icon/svg-icon.component";
import { DialogBodyComponent } from "../../components/dialog-body/dialog-body.component";
import { DialogHeaderComponent } from "../../components/dialog-header/dialog-header.component";
import { MatInputModule } from "@angular/material/input";
import { HasErrorPipe } from "../../pipes/has-error.pipe";

export interface EditUserDialogData {
  user: User;
}

export interface EditUserDialogFormValue {
  currentPassword: string;
  newPassword: string;
}

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss'],
})
export class EditUserDialogComponent implements OnInit {
  private _formBuilder = inject(UntypedFormBuilder);
  private _accountService = inject(AccountService);
  private _matDialogRef = inject(MatDialogRef<EditUserDialogComponent>);
  private _destroyRef = inject(DestroyRef);

  readonly _dialogData = inject(MAT_DIALOG_DATA);
  readonly formGroup = this._formBuilder.group({
    organizationName: [''],
    email: [''],
    currentPassword: ['', Validators.required],
    newPassword: ['', [Validators.required, Validators.minLength(8)]],
    confirmNewPassword: ['', [Validators.required, Validators.minLength(8)]],
  });
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  loading: boolean = false;
  disableClose: boolean = false;

  ngOnInit(): void {
    // Set confirm password validator
    this.formGroup.controls.confirmNewPassword.addValidators(
      confirmPasswordValidator(this.formGroup.controls.newPassword)
    );

    this.formGroup.controls.newPassword.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.formGroup.controls.confirmNewPassword.updateValueAndValidity();
    });

    this.formGroup.controls.organizationName.setValue(this._accountService.organization()?.name);
    this.formGroup.controls.email.setValue(this._accountService.user()?.email);
    this.formGroup.controls.organizationName.disable();
    this.formGroup.controls.email.disable();
  }

  onCancel(): void {
    if (this.disableClose) {
      return;
    }

    this._matDialogRef.close(false);
  }

  onSubmit(): void {
    if (this.loading) {
      return;
    }

    if (this.formGroup.invalid) {
      return;
    }

    this.loading = true;
    this.disableClose = true;
    this._matDialogRef.disableClose = true;

    const { currentPassword, newPassword } = this.formGroup.value;

    this._accountService.changeUserPassword(this._dialogData.user.id, currentPassword, newPassword).subscribe({
      next: (users) => {
        this._matDialogRef.close(true);
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        this.disableClose = false;
        this._matDialogRef.disableClose = false;
      },
    });
  }
}

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from "@angular/core";
import { Observable, map } from 'rxjs';

export class Country {
  constructor(public name: string, public code: string, public flag: string) {}
}

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private _httpClient = inject(HttpClient);

  getCountries(): Observable<Country[]> {
    return this._httpClient.get<Country[]>(`/assets/countries.json`).pipe(
      map((countries) =>
        countries.map((country) => {
          return new Country(country.name, country.code, country.flag);
        })
      )
    );
  }
}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from '../services/account.service';
import { SpinnerService } from '../services/spinner.service';

const INTERNAL_SERVER_ERROR = 'Internal server error';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private _toastrService = inject(ToastrService);
  private _accountService = inject(AccountService);
  private _spinnerService = inject(SpinnerService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true,
    });

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        this.processError(error);
        this._spinnerService.hide();

        return throwError(() => error);
      }),
    );
  }

  private processError(error: HttpErrorResponse): void {
    if (error.status === 401) {
      this._accountService.handleLogout();
    }

    const errorMessage = error.error?.errorMessage || INTERNAL_SERVER_ERROR;

    this._toastrService.error(errorMessage);
  }
}

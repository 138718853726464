import { inject, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safeHTML',
})
export class SafeHTMLPipe implements PipeTransform {
  private _domSanitizer = inject(DomSanitizer)

  transform(html: string): SafeHtml {
    return this._domSanitizer.bypassSecurityTrustHtml(html);
  }
}

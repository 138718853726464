@if (headingContent) {
  <h3 class="text-gray-900 text-center text-lg leading-lg font-medium pb-4">
    <ng-container *ngTemplateOutlet="headingContent"></ng-container>
  </h3>
}

@if (descriptionContent) {
  <p class="text-gray-500 text-center text-sm leading-sm pb-6">
    <ng-container *ngTemplateOutlet="descriptionContent"></ng-container>
  </p>
}

<ng-content></ng-content>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-add-payment-wizard-step',
  templateUrl: './add-payment-wizard-step.component.html',
  styleUrls: ['./add-payment-wizard-step.component.scss'],
})
export class AddPaymentWizardStepComponent {
  @Input() title!: string;
  @Input() description!: string;
  @Input() active?: boolean;
  @Input() hasNext?: boolean;
  @Input() complete?: boolean;

  @Output() stepClick = new EventEmitter<void>();

  paginationTransition: boolean = false;

  onStepClick(): void {
    this.paginationTransition = true;
    this.stepClick.emit();
  }
}

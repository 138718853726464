import { Component, inject } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService } from 'src/app/common/services/account.service';
import {
  ButtonComponent,
  ButtonPadding,
  ButtonStyle,
  ButtonType
} from "src/app/common/components/button/button.component";
import { DialogBodyComponent } from "../../components/dialog-body/dialog-body.component";
import { DialogHeaderComponent } from "../../components/dialog-header/dialog-header.component";
import { MatError, MatFormField } from "@angular/material/form-field";
import { LabelComponent } from "../../components/label/label.component";
import { SvgIconComponent } from "../../components/svg-icon/svg-icon.component";
import { HasErrorPipe } from "../../pipes/has-error.pipe";

@Component({
  selector: 'app-password-reset-dialog',
  templateUrl: './password-reset-dialog.component.html',
  styleUrls: ['./password-reset-dialog.component.scss'],
})
export class PasswordResetDialogComponent {
  private _formBuilder = inject(UntypedFormBuilder);
  private _accountService = inject(AccountService);

  readonly _matDialogRef = inject(MatDialogRef<PasswordResetDialogComponent>);
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly formGroup = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  loading: boolean = false;
  resetPasswordSubmitted: boolean = false;

  onSubmit(): void {
    if (this.loading) {
      return;
    }

    if (this.formGroup.invalid) {
      return;
    }

    const { email } = this.formGroup.value;

    this.loading = true;

    this._accountService.passwordReset(email).subscribe({
      next: () => {
        this.resetPasswordSubmitted = true;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }
}

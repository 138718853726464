import { Component, Input, OnInit } from '@angular/core';
import { zoomOutDownOnLeaveAnimation } from 'angular-animations';
import { SvgIconComponent } from "../svg-icon/svg-icon.component";
import { NgClass } from "@angular/common";

export enum DismissibleNoticeStyle {
  NOTICE_0,
  NOTICE_1,
}

enum DismissibleNoticeStatus {
  DISMISSED,
}

const ANIMATIONS_DURATION = 500;

@Component({
  selector: 'app-dismissible-notice',
  templateUrl: './dismissible-notice.component.html',
  styleUrls: ['./dismissible-notice.component.scss'],
  animations: [zoomOutDownOnLeaveAnimation({ duration: ANIMATIONS_DURATION })],
})
export class DismissibleNoticeComponent implements OnInit {
  @Input() storageKey!: string;
  @Input() icon!: string;
  @Input() dismissibleNoticeStyle!: DismissibleNoticeStyle;

  readonly DismissibleNoticeStyle = DismissibleNoticeStyle;

  active: boolean = true;

  ngOnInit(): void {
    const dismissibleNoticeStatus = sessionStorage.getItem(this.storageKey);

    if (!dismissibleNoticeStatus) {
      return;
    }

    this.active = JSON.parse(dismissibleNoticeStatus) !== DismissibleNoticeStatus.DISMISSED;
  }

  onDismiss(): void {
    this.active = false;

    sessionStorage.setItem(this.storageKey, JSON.stringify(DismissibleNoticeStatus.DISMISSED));
  }
}

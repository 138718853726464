import { Component, inject, Input } from "@angular/core";
import { UntypedFormControl } from '@angular/forms';
import { BillingPeriod, BillingPeriodType, CustomerPrices } from 'src/app/common/models/billing';
import { BillingService } from 'src/app/common/services/billing.service';
import { AgentsControlValue } from '../agents-configuration/agents-configuration.component';
import { PluginTypesControlValue } from '../plugins-configuration/plugins-configuration.component';
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { SvgIconComponent } from "../../../common/components/svg-icon/svg-icon.component";
import { CurrencyPipe } from "@angular/common";
import { TotalClusterCostsPipe } from "../../../common/pipes/total-cluster-costs..pipe";
import { MatRipple } from "@angular/material/core";

@Component({
  selector: 'app-costs-switcher',
  templateUrl: './costs-switcher.component.html',
  styleUrls: ['./costs-switcher.component.scss'],
})
export class CostsSwitcherComponent {
  @Input() customerPrices?: CustomerPrices;
  @Input() agentsControlValue!: AgentsControlValue;
  @Input() pluginTypesControlValue!: PluginTypesControlValue;
  @Input() billingPeriodTypeControl = new UntypedFormControl(BillingPeriodType.HOUR);

  private _billingService = inject(BillingService);

  readonly billingPeriod = this._billingService.PERIOD;
  readonly billingPeriodsEntries = Object.entries(this._billingService.PERIOD).map(
    ([billingPeriodType, billingPeriod]) =>
      ({
        billingPeriodType,
        billingPeriod,
      } as {
        billingPeriodType: BillingPeriodType;
        billingPeriod: BillingPeriod;
      })
  );

  currentBillingPeriod = this.billingPeriod[BillingPeriodType.HOUR];

  onChangeBillingPeriodType(billingPeriod: BillingPeriod, billingPeriodType: BillingPeriodType): void {
    this.currentBillingPeriod = billingPeriod;
    this.billingPeriodTypeControl.setValue(billingPeriodType);
  }
}

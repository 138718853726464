<div class="flex items-center gap-2 text-sm leading-sm">
  <div class="font-mono font-semibold text-gray-700">
    CHF
    @if (billingPeriod[billingPeriodTypeControl.value]; as billingPeriod) {
      {{
        customerPrices
          | totalClusterCosts : agentsControlValue : pluginTypesControlValue : billingPeriod
          | currency : "" : "" : billingPeriod.decimalPlaces
      }}
    }
  </div>

  <button
    type="button"
    class="text-gray-500 flex items-center gap-1 focus:outline-none focus:text-primary-400"
    [matMenuTriggerFor]="billingPeriodsMenu"
  >
    <span>per {{ currentBillingPeriod.unit }}</span>
    <app-svg-icon name="chevron-down" class="text-xs"/>
    <span>runtime</span>
  </button>

  <mat-menu #billingPeriodsMenu>
    @for (entry of billingPeriodsEntries; track entry) {
      <button
            type="button"
            mat-menu-item
            matRipple
            (click)="onChangeBillingPeriodType(entry.billingPeriod, entry.billingPeriodType)"
          >
        {{ entry.billingPeriod.unit }}
      </button>
    }
  </mat-menu>
</div>

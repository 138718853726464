import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {DestroyRef, inject, Injectable, signal} from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, NavigationEnd} from "@angular/router";
import {interval, Observable, of, pairwise, startWith, take, takeWhile, throwError} from "rxjs";
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Address } from '../models/address';
import { LoginResponse } from '../models/login-response.interface';
import { Organization } from '../models/organization';
import { RegistrationState } from '../models/registration-state.enum';
import { User } from '../models/user';
import { BaseService } from './base.service';
import { BillingService } from './billing.service';
import { PortalService } from './portal.service';
import { SpinnerService } from './spinner.service';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';

interface Snack {
  message: string;
  triggerRoute: string;
  duration?: number;
}

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  private _router = inject(Router);
  private _portalService = inject(PortalService);
  private _billingService = inject(BillingService);
  private _snackBar = inject(MatSnackBar);
  private _spinnerService = inject(SpinnerService);
  private _destroyRef = inject(DestroyRef);

  private userInternal = signal<User | null>(null);
  private organizationInternal = signal<Organization | null>(null);

  private snacks: Snack[] = [];

  readonly user = this.userInternal.asReadonly();
  readonly organization = this.organizationInternal.asReadonly();
  private readonly user$ = toObservable(this.user);

  constructor(
  ) {
    super();

    this._router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd)
    ).subscribe((val: NavigationEnd) => this.checkForSnacks(val));
  }

  clearUser(): void {
    this.userInternal.set(null);
  }

  clearOrganization(): void {
    this.organizationInternal.set(null);
  }

  getOrganization(): Observable<Organization> {
    return this._http.get<Organization>(`${environment.apiUrl}/organization`).pipe(
      map(({ name, registrationState, id, addressId, phoneNumber, customFields, pendingQuotaRequests }) => {
        return new Organization(
          name,
          registrationState,
          id,
          addressId,
          phoneNumber,
          customFields,
          pendingQuotaRequests
        );
      }),
      tap((organization) => {
        this.organizationInternal.update((organizationValue) => ({
          ...(organizationValue ?? {}),
          ...organization
        }));
      })
    );
  }

  register(registrationData: any) {
    return this._http.post(`${environment.apiUrl}/registration/register`, registrationData, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  updateOrganization(addressData: any) {
    const payload = {
      ...addressData,
      name: this.organization()?.name,
      country: addressData.country.name,
    };

    return this._http.post(`${environment.apiUrl}/organization/update-organization`, payload, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  joinOrganization(registrationData: any) {
    return this._http.post(`${environment.apiUrl}/organization/join`, registrationData, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  addUser(registrationData: any) {
    return this._http.post(`${environment.apiUrl}/organization/add-user`, registrationData, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  changeUserPassword(userId: string, oldPassword: string, newPassword: string) {
    return this._http.post(
      `${environment.apiUrl}/registration/${userId}/password`,
      { oldPassword: oldPassword, newPassword: newPassword },
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  }

  getAddress() {
    return this._http.get<Address>(`${environment.apiUrl}/organization/address`);
  }

  refreshUserUntilRegistrationStateChange(): void {
    const initialState = this.user()?.registrationState;
    interval(1000)
      .pipe(
        switchMap(() => this.getUser().pipe(catchError(() => of(undefined)))),
        map((data) => data?.user?.registrationState),
        startWith(initialState),
        pairwise(),
        takeWhile(([previous, current]) => previous === current),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();
  }

  refreshOrganizationUntilRegistrationStateChange(): void {
    const initialState = this.organization()?.registrationState;
    interval(1000)
      .pipe(
        switchMap(() => this.getOrganization().pipe(catchError(() => of(undefined)))),
        map((organization) => organization?.registrationState),
        startWith(initialState),
        pairwise(),
        takeWhile(([previous, current]) => previous === current),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();
  }

  private getUser(): Observable<{ user: User, isAuthenticated: boolean }> {
    return this._http.get<any>(`${environment.apiUrl}/session`)
      .pipe(
        map((userData) => {
          const user: User = {
            email: userData.email,
            organizationId: userData.organizationId,
            registrationState: userData.registrationState,
            fullName: userData.fullName,
            id: userData.userId,
            organizationOwner: userData.organizationOwner,
          };
          const isAuthenticated = !!userData.authenticated;
          return {user, isAuthenticated};
        }),
        tap(({user}) => this.userInternal.set(user))
      )
  }

  authenticateAsObservable(): Observable<boolean> {
    return this.getUser()
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 502 || error.status === 503) {
            this._router.navigate(['/maintenance']);
          }
          return throwError(() => error);
        }),
        map(({isAuthenticated}) => isAuthenticated)
      );
  }

  login(email: string, password: string): Observable<LoginResponse> {
    return this._http.post(`${environment.apiUrl}/session/login`, { email, password }).pipe(
      switchMap((data: LoginResponse) => {
        if (data.user) {
          this.userInternal.set(data.user);
          return this.authenticateAsObservable().pipe(map(() => data));
        }
        return of(data);
      })
    );
  }

  passwordReset(email: string) {
    return this._http.post(`${environment.apiUrl}/registration/password-reset-request`, { email });
  }

  passwordResetVerify(id: string, newPassword: string) {
    return this._http.post(`${environment.apiUrl}/registration/password-reset-request/${id}/verify`, { newPassword });
  }

  logout() {
    this._spinnerService.show();

    this._http
      .post(`${environment.apiUrl}/session/logout`, {})
      .pipe(tap(() => this._spinnerService.hide()))
      .subscribe(() => this.handleLogout());
  }

  handleLogout() {
    // cancel interval polling
    this._portalService.cancelAllIntervals();
    this._billingService.cancelAllIntervals();

    // invalidate cache FIXME: we should not have to target individual services. Maybe some kind of subscription?
    this._billingService.clearBilling();
    this._billingService.clearCustomer();
    this._billingService.clearInvoices();
    this.clearUser();
    this.clearOrganization();

    // reloading the page to solve issue with deleted observables
    window.location.href = '/';
  }

  getUsers() {
    return this._http.get<User[]>(`${environment.apiUrl}/organization/users`);
  }

  getUserById(id: string) {
    return this._http.get<User>(`${environment.apiUrl}/organization/users/${id}`);
  }

  updateUser(email: string, value: any) {
    return this._http.put(`${environment.apiUrl}/users/${email}`, value).pipe(
      tap(() => {
        // update stored company if the logged in company updated their own record
        if (email == this.user()?.email) {
          // update local storage
          this.userInternal.update((userValue) => ({
            ...(userValue ?? null),
            ...value
          }))
        }
      })
    );
  }

  verifyMail(id: string) {
    return this._http.get(`${environment.apiUrl}/registration/verify/${id}`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  isLoggedIn(): Observable<boolean> {
    return this.user$.pipe(
      first((user) => !!user?.email),
      map((user) => !!user?.email)
    );
  }

  isAuthenticated(): Observable<boolean> {
    const userValue = this.user();
    if (!!userValue?.email && userValue?.registrationState !== RegistrationState.NEW) {
      return of(true);
    } else {
      return this.authenticateAsObservable()
        .pipe(
          map((isAuthenticated) => {
            if (!isAuthenticated) {
              return false;
            }
            const userValue = this.user();
            return !!userValue?.email && userValue?.registrationState !== RegistrationState.NEW;
          })
        )
    }
  }

  deleteUser(id: string) {
    return this._http.delete(`${environment.apiUrl}/organization/delete-user/${id}`).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  /*
   *  (optional) triggerRoute: the route on which this snack will be shown, any route by default
   */
  addSnack(message: string, triggerRoute?: string, duration?: number) {
    this.snacks.push({
      message: message,
      triggerRoute: triggerRoute ?? 'ALL',
      duration: duration,
    });
  }

  checkForSnacks(event: NavigationEnd) {
    for (let key in this.snacks) {
      if (this.snacks[key].triggerRoute === 'ALL' || event.url.endsWith(this.snacks[key].triggerRoute)) {
        const openSnack = this._snackBar.open(this.snacks[key].message);
        setTimeout(() => {
          openSnack.dismiss();
        }, this.snacks[key].duration ?? 5000);
        delete this.snacks[key];
      }
    }
  }
}

<div
  class="text-primary-800 bg-primary-50 border border-b-0 border-primary-600 rounded-tl-lg rounded-tr-lg py-2 px-3 flex items-center gap-2"
>
  <div class="font-medium">Total cost</div>

  <app-svg-icon
    name="help-circle"
    matTooltip="For a running cluster with the configured number of agents. Stopping a cluster or reducing the number of agents when not in use will reduce the costs. This can be done at any time in the cluster detail page."
  />
</div>

<div class="border-b border-b-primary-600"></div>

<div class="bg-gray-50 border border-t-0 border-primary-600 rounded-bl-lg rounded-br-lg py-2.5 px-3">
  <app-costs-switcher
    [customerPrices]="customerPrices"
    [agentsControlValue]="agentsControlValue"
    [pluginTypesControlValue]="pluginTypesControlValue"
    [billingPeriodTypeControl]="billingPeriodTypeControl"
  />
</div>

import { Component, Input } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.sass'],
})
export class LabelComponent {
  @Input() control: AbstractControl;
  @Input() title: string;
  @Input() required: boolean = false;
  @Input() isActive: boolean = false;

  focus(): void {
    this.isActive = true;
  }

  focusout(): void {
    this.isActive = false;
  }
}

import { Component, inject, OnInit } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.less']
})
export class MaintenanceComponent implements OnInit {
  private _http = inject(HttpClient);
  private _router = inject(Router);

  ngOnInit(): void {
    this.checkServerAvailability();
    setInterval(() => { this.checkServerAvailability() }, 1000 * 60 * 10 /* 10 minutes */);
  }

  checkServerAvailability(){
    this._http.get<any>(`${environment.apiUrl}/session`).subscribe(() => this._router.navigate(['/login']));
  }

}

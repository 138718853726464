import { inject, Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';
import { RedirectService } from "./redirect.service";

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  private _accountService = inject(AccountService);
  private _router = inject(Router);

  canActivate(): Observable<boolean> {
    return this._accountService.isAuthenticated().pipe(
      map((isLoggedIn) => {
        if (!isLoggedIn) {
          const currentUrl = window.location.pathname;
          const redirectService = new RedirectService();
          if (currentUrl.includes('/upgrade')) {
            redirectService.setRedirectUrl(currentUrl);
          }

          this._router.navigate(['/login']);
          return false;
        }

        return true;
      })
    );
  }
}

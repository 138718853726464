import { Component } from '@angular/core';
import { DismissibleNoticeComponent, DismissibleNoticeStyle } from "../dismissible-notice.component";
import { SvgIconComponent } from "../../svg-icon/svg-icon.component";

@Component({
  selector: 'app-stripe-dismissible-notice',
  templateUrl: './stripe-dismissible-notice.component.html',
  styleUrls: ['./stripe-dismissible-notice.component.scss'],
})
export class StripeDismissibleNoticeComponent {
  readonly DismissibleNoticeStyle = DismissibleNoticeStyle;
}

import { Component, inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import {
  ButtonPadding,
  ButtonStyle,
  ButtonType
} from "src/app/common/components/button/button.component";
import { QuotaKey } from '../../models/quota';
import { PortalService } from '../../services/portal.service';
import { Organization } from "../../models/organization";
import { AccountService } from "../../services/account.service";
import { RegistrationState } from "../../models/registration-state.enum";

export interface RequestAgentQuotaDialogFormValue {
  requestedValue: number | null;
  requestedReason: string | null;
}

const QUOTA_REQUESTED_SUCCESSFULLY = 'Your request was send and will be processed in the next days';

@Component({
  selector: 'app-request-agent-quota-dialog',
  templateUrl: './request-agent-quota-dialog.component.html',
  styleUrls: ['./request-agent-quota-dialog.component.scss'],
})
export class RequestAgentQuotaDialogComponent implements OnInit {
  private _portalService = inject(PortalService);
  private _toastrService = inject(ToastrService);
  private _formBuilder = inject(UntypedFormBuilder);
  private _accountService = inject(AccountService);
  private _matDialogRef = inject(MatDialogRef<RequestAgentQuotaDialogComponent>);

  readonly _dialogData = inject(MAT_DIALOG_DATA);
  readonly formGroup = this._formBuilder.group({
    requestedValue: [null, [Validators.required]],
    requestedReason: [null, [Validators.required]],
  });
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  loading: boolean = false;
  quotaKey?: QuotaKey;
  organization?: Organization;
  readonly RegistrationState = RegistrationState;

  ngOnInit(): void {
    this.quotaKey = this._dialogData.quotaKey;

    this.loading = true;

    this._accountService.getOrganization().subscribe((organization) => {
      this.organization = organization;
      this.loading = false;
    });
  }

  onCancel(): void {
    this._matDialogRef.close(false);
  }

  onSubmit(): void {
    const { requestedValue, requestedReason } = this.formGroup.value as RequestAgentQuotaDialogFormValue;

    if (!requestedValue || !requestedReason) {
      return;
    }

    this.loading = true;

    this._portalService.requestQuota(this._dialogData.quotaKey, requestedValue, requestedReason).subscribe({
      next: () => {
        this._toastrService.success(QUOTA_REQUESTED_SUCCESSFULLY);
        this.loading = false;
        this._matDialogRef.close();
      },
      error: () => {
        this.loading = false;
      },
    });
  }
}

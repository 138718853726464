<h5 class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">Permanent Agents</h5>

<p class="text-sm leading-sm text-gray-500 pb-5">
Keywords (automation scripts) are executed on agents. By default, agents requirements are auto determined and provisioned for each execution (<a href="https://step.dev/knowledgebase/userdocs/agent-provisioning/">read more</a>).
For advanced cases, you have the option to configure permanent agents. These agents will remain active as long as the cluster is running.
</p>

@if (agentTypes.length) {
  <div class="flex flex-col gap-5 pb-6">
    @for (agentType of agentTypes; track agentType) {
      @if (agentByType[agentType]; as agent) {
        @if (sliderControlByAgentType[agentType]; as sliderControl) {
          @if (remainingQuotaByAgentType[agentType]; as remainingQuota) {
            <app-agent-configuration
              [agent]="agent"
              [billingPeriod]="billingPeriod"
              [customerPrices]="customerPrices"
              [sliderControl]="sliderControl"
              [remainingQuota]="remainingQuota"
              (agentRemove)="onRemoveAgent($event)"
            />
          }
        }
      }
    }
  </div>
}

<div class="flex gap-3">
  <button
    type="button"
    [matMenuTriggerFor]="agentsMenu"
    [disabled]="allAgentsAdded"
    class="text-sm leading-sm font-medium text-gray-500 flex items-center gap-2 focus:outline-none focus:text-primary-400"
  >
    <app-svg-icon name="plus"/>
    Add another agent
  </button>

  <div class="text-sm leading-sm text-gray-500">You are using {{ totalUsedQuota }} out of {{ maxQuota }} agents available in your current quota</div>

  <button type="button" (click)="onRequestQuota()" class="text-sm leading-sm link-0">Request higher quota</button>
</div>

<mat-menu #agentsMenu>
  @for (agent of _agents; track agent) {
    @if (!activeByAgentType[agent.type]) {
      <button
        type="button"
        mat-menu-item
        matRipple
        (click)="onAddAgent(agent.type)"
      >
        <div class="flex items-start gap-3 text-left">
          <div class="w-12 h-8 bg-white border border-gray-100 rounded-md px-2 py-1">
            <img [src]="agent.imgSrc" [alt]="agent.imgAlt" class="w-full h-full" />
          </div>

          <div class="flex-1 text-sm leading-sm">
            <div class="font-medium text-gray-700">{{ agent.title }}</div>
            <div class="text-gray-500">{{ agent.description }}</div>
          </div>
        </div>
      </button>
    }
  }
</mat-menu>

import { inject, Injectable } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from '../dialogs/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  private _matDialog = inject(MatDialog);

  confirm(dialogData: ConfirmationDialogData): Observable<boolean> {
    return this._matDialog.open(ConfirmationDialogComponent, { data: dialogData }).afterClosed() as Observable<boolean>;
  }
}

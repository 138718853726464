<mat-form-field subscriptSizing="dynamic" class="w-full">
    <input
      #countriesInput
      type="text"
      matInput
      [matAutocomplete]="countriesAutocomplete"
      [formControl]="countryControl"
      (click)="onCountriesInputClick()"
      (focus)="onCountriesInputFocus($event)"
      (blur)="onCountriesInputBlur()"
      (keydown)="onCountriesInputKeyDown($event)"
      [attr.data-active]="countriesInputActive"
      [attr.data-invalid]="countriesInputInvalid"
      class="peer data-invalid:border-error-500 relative z-[1]"
    />

    @if (countriesInputInvalid && !countriesAutocompleteOpen) {
      <mat-error
        [@transitionMessages]="countriesInputInvalid ? 'enter' : ''"
        class="block text-xs leading-xs font-medium pt-2"
      >
        Country is required
      </mat-error>
    }

    <div
      #countriesInputOverlay
      (click)="countriesInput.click()"
      (mousedown)="$event.preventDefault()"
      class="overlay-fix absolute inset-0 border border-transparent py-2 px-3 flex items-center gap-2 cursor-pointer"
    >
      <div class="flex-1">
        @if (!countryControl.value) {
          <div class="text-sm leading-sm text-gray-300 text-left">Country</div>
        }
      </div>

      <app-svg-icon name="chevron-down" class="text-[0.625rem] text-gray-300"/>
    </div>

    <div
      #countriesInputOverlay
      (click)="countriesInput.click()"
      class="overlay-fix absolute inset-0 border border-gray-300 rounded-md bg-white peer-data-active:hidden py-2 px-3 flex items-center gap-2 cursor-pointer z-[2]"
    >
      @if (!countriesInputActive) {
        <div [innerHTML]="countryControl.value.flag | safeHTML" class="text-xl"></div>
        <div class="flex-1 min-w-0 text-left text-sm leading-sm overflow-hidden text-ellipsis whitespace-nowrap">
          {{ countryControl.value.name }}
        </div>
        <app-svg-icon name="chevron-down" class="text-[0.625rem] text-gray-300"></app-svg-icon>
      }
    </div>

  <mat-autocomplete
    #countriesAutocomplete="matAutocomplete"
    (opened)="onCountriesAutocompleteOpened()"
    (closed)="onCountriesAutocompleteClosed()"
    (optionSelected)="onCountriesOptionSelected()"
  >
    @for (country of filteredCountries; track country.code) {
      <mat-option
            [value]="country"
            (click)="onCountriesOptionClick()"
            (mousedown)="onCountriesOptionMouseDown($event)"
          >
            <div class="flex items-center gap-3">
              <div [innerHTML]="country.flag | safeHTML" class="text-xl"></div>
              <div class="flex-1 w-0 text-sm leading-sm overflow-hidden text-ellipsis whitespace-nowrap w-full">
            {{ country.name }}
          </div>
        </div>
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>

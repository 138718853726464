<button
  type="button"
  (click)="togglePasswordVisibility()"
  (keydown)="onKeyDown($event)"
  (mousedown)="onMouseDown($event)"
  class="flex items-center focus:outline-none focus:text-primary-500"
>
  @if (!passwordVisible) {
    <app-svg-icon name="eye"/>
  } @else {
    <app-svg-icon name="eye-off"/>
  }
</button>

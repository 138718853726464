import { Component, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RegistrationState } from 'src/app/common/models/registration-state.enum';
import {
  ButtonComponent,
  ButtonPadding,
  ButtonStyle,
  ButtonType
} from "src/app/common/components/button/button.component";
import { AccountService } from '../../services/account.service';
import { DialogHeaderComponent } from "../../components/dialog-header/dialog-header.component";
import { DialogBodyComponent } from "../../components/dialog-body/dialog-body.component";

@Component({
  selector: 'app-require-verification-dialog',
  templateUrl: './require-verification-dialog.component.html',
  styleUrls: ['./require-verification-dialog.component.scss'],
})
export class RequireVerificationDialogComponent {
  private _accountService = inject(AccountService);
  private _router = inject(Router);
  private _matDialogData = inject(MAT_DIALOG_DATA);

  readonly _matDialogRef = inject(MatDialogRef<RequireVerificationDialogComponent>);
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  loading: boolean = false;

  onContinue(): void {
    if (this.loading) {
      return;
    }

    this.loading = true;

    const { email, password } = this._matDialogData;

    this._accountService.login(email, password).subscribe({
      next: ({ organization }) => {
        if (organization?.registrationState !== RegistrationState.NEW) {
          this._router.navigate(['/cluster']);
          this._matDialogRef.close();
        } else {
          this.onContinue();
          return;
        }

        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }
}

<app-dialog-header icon="alert-triangle" (dialogClose)="onCancel()"/>

<app-dialog-body>
  <ng-template #heading>Billing is On-Hold</ng-template>
  <ng-template #description>
    It looks like there is an issue with your payment method. Please
    <a href="https://step.dev/contact/" target="_blank" class="link-0">contact us</a> so the issue can be resolved
    and you can continue using step!
  </ng-template>
</app-dialog-body>

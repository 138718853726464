import { Component, inject } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { CloseAccountDialogComponent } from 'src/app/common/dialogs/close-account-dialog/close-account-dialog.component';
import { ButtonComponent, ButtonPadding, ButtonStyle, ButtonType } from "../../button/button.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@Component({
  selector: 'app-organization-account-settings',
  templateUrl: './organization-account-settings.component.html',
  styleUrls: ['./organization-account-settings.component.scss'],
})
export class OrganizationAccountSettingsComponent {
  private _matDialog = inject(MatDialog);

  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  onCloseAccount(): void {
    this._matDialog.open(CloseAccountDialogComponent);
  }
}

import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    _paq: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class GtagService {
  private readonly gTagId = environment.gTagId;

  constructor(private router: Router) {
    this.initializeGoogleAnalytics();
    this.trackPageViews();
  }

  private initializeGoogleAnalytics() {

    // Load the Cookiebot script
    const cookieBotScript = document.createElement('script');
    cookieBotScript.src = "https://consent.cookiebot.com/uc.js";
    cookieBotScript.setAttribute('data-cbid', '60f31a85-9e16-4b7f-95f3-d1c7f9c2a5bf');
    cookieBotScript.setAttribute('data-blockingmode', 'none');
    document.head.appendChild(cookieBotScript);

    // Load the gtag script
    const gTagManagerScript = document.createElement('script');
    gTagManagerScript.async = true;
    gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.gTagId}`;
    document.head.appendChild(gTagManagerScript);

    // Initialize GA4
    const gaScript = document.createElement('script');
    gaScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${this.gTagId}');
    `;
    document.head.appendChild(gaScript);
  }

 private trackPageViews() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // The following doesn't seem to be required anymore. GA seems to automatically detect the page_path
        //window.gtag!('config', this.gTagId, { page_path: event.urlAfterRedirects });
        if (window._paq) {
          // Notify Matomo of the new page view
          window._paq.push(['setCustomUrl', event.urlAfterRedirects]);
          window._paq.push(['setDocumentTitle', document.title]);
          window._paq.push(['trackPageView']);
        }
      }
    });
  }
}

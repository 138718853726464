import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxStripeModule } from 'ngx-stripe';
import { ToastrModule } from 'ngx-toastr';
import { register } from 'swiper/element/bundle';
import { CloseAccountDialogComponent } from './common/dialogs/close-account-dialog/close-account-dialog.component';
import { OnHoldDialogComponent } from './common/dialogs/on-hold-dialog/on-hold-dialog.component';
import { HttpRequestInterceptor } from './common/helpers/http-interceptor.interceptor';
import { simulatedBackendProvider } from './common/helpers/simulated-backend';
import { AbsolutePipe } from './common/pipes/absolute.pipe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UpgradeComponent } from "./pages/upgrade/upgrade.component";
import { ToastComponent } from './common/components/toast/toast.component';
import { MaintenanceComponent } from "./pages/error-pages/maintenance/maintenance.component";
import { GtagService } from "./common/services/gtag.service";
import { LabelComponent } from './common/components/label/label.component';
import { WakeClusterComponent } from "./pages/cluster/wake-cluster/wake-cluster.component";
import { CostsSwitcherComponent } from "./pages/cluster/costs-switcher/costs-switcher.component";
import { RegistrationComponent } from "./pages/account/registration/registration.component";
import { ClusterDetailsComponent } from "./pages/cluster/cluster-details/cluster-details.component";
import { BillingComponent } from "./pages/billing/billing.component";
import { LoginComponent } from "./pages/account/login/login.component";
import { VerifyComponent } from "./pages/account/verify/verify.component";
import { ExtractBalancePipe } from "./common/pipes/extract-balance.pipe";
import { ClusterListComponent } from "./pages/cluster/cluster-list.component";
import {
  RequireVerificationDialogComponent
} from "./common/dialogs/require-verification-dialog/require-verification-dialog.component";
import {
  RequestAgentQuotaDialogComponent
} from "./common/dialogs/request-agent-quota-dialog/request-agent-quota-dialog.component";
import { PasswordResetComponent } from "./pages/account/password-reset/password-reset.component";
import { ClusterStatusComponent } from "./common/components/cluster-status/cluster-status.component";
import { CalcTimePipe } from "./common/pipes/calc-time.pipe";
import { CreateClusterComponent } from "./pages/cluster/create-cluster/create-cluster.component";
import { InvoiceDetailsComponent } from "./pages/billing/invoice-details/invoice-details.component";
import { SpinnerDialogComponent } from "./common/dialogs/spinner-dialog/spinner-dialog.component";
import { PasswordResetDialogComponent } from "./common/dialogs/password-reset-dialog/password-reset-dialog.component";
import { SvgIconComponent } from "./common/components/svg-icon/svg-icon.component";
import { SpinnerComponent } from "./common/components/spinner/spinner.component";
import { AuthFooterComponent } from "./pages/account/auth-footer/auth-footer.component";
import { SidebarLayoutComponent } from "./common/components/sidebar-layout/sidebar-layout.component";
import { SidebarProfileComponent } from "./common/components/sidebar-layout/sidebar-profile/sidebar-profile.component";
import { ClusterStatusIconComponent } from "./common/components/cluster-status-icon/cluster-status-icon.component";
import { SettingsComponent } from "./common/components/settings/settings.component";
import { ButtonComponent } from "./common/components/button/button.component";
import { DialogHeaderComponent } from "./common/components/dialog-header/dialog-header.component";
import { DialogBodyComponent } from "./common/components/dialog-body/dialog-body.component";
import { AddPaymentDialogComponent } from "./common/dialogs/add-payment-dialog/add-payment-dialog.component";
import {
  AddPaymentWizardDialogComponent
} from "./common/dialogs/add-payment-wizard-dialog/add-payment-wizard-dialog.component";
import {
  AddPaymentWizardStepComponent
} from "./common/dialogs/add-payment-wizard-dialog/add-payment-wizard-step/add-payment-wizard-step.component";
import { DismissibleNoticeComponent } from "./common/components/dismissible-notice/dismissible-notice.component";
import {
  StripeDismissibleNoticeComponent
} from "./common/components/dismissible-notice/stripe-dismissible-notice/stripe-dismissible-notice.component";
import {
  PaymentsDismissibleNoticeComponent
} from "./common/components/dismissible-notice/payments-dismissible-notice/payments-dismissible-notice.component";
import { BillingDetailsStepComponent } from "./common/components/billing-details-step/billing-details-step.component";
import { BillingAddressStepComponent } from "./common/components/billing-address-step/billing-address-step.component";
import {
  StripeCardInputWrapperComponent
} from "./common/components/stripe-card-input-wrapper/stripe-card-input-wrapper.component";
import { SafeHTMLPipe } from "./common/pipes/safe-html.pipe";
import { TabComponent } from "./common/components/tabset/tab/tab.component";
import { TabsetComponent } from "./common/components/tabset/tabset.component";
import {
  UserPersonalInfoSettingsComponent
} from "./common/components/settings/user-personal-info-settings/user-personal-info-settings.component";
import {
  UserChangePasswordSettingsComponent
} from "./common/components/settings/user-change-password-settings/user-change-password-settings.component";
import {
  OrganizationAccountSettingsComponent
} from "./common/components/settings/organization-account-settings/organization-account-settings.component";
import {
  OrganizationAddressSettingsComponent
} from "./common/components/settings/organization-address-settings/organization-address-settings.component";
import {
  OrganizationMembersSettingsComponent
} from "./common/components/settings/organization-members-settings/organization-members-settings.component";
import {
  PaymentCardSettingsComponent
} from "./common/components/settings/payment-card-settings/payment-card-settings.component";
import { AddressSettingsComponent } from "./common/components/address-settings/address-settings.component";
import { CountrySelectComponent } from "./common/components/country-select/country-select.component";
import { AsFormControlPipe } from "./common/pipes/as-form-control.pipe";
import { SkeletonLoaderComponent } from "./common/components/skeleton-loader/skeleton-loader.component";
import { CardBrandComponent } from "./common/components/card-brand/card-brand.component";
import { CardDetailsComponent } from "./common/components/card-details/card-details.component";
import { CardSettingsComponent } from "./common/components/card-settings/card-settings.component";
import { PasswordVisibilityComponent } from "./common/components/password-visibility/password-visibility.component";
import {
  AddOrganizationMemberDialogComponent
} from "./common/dialogs/add-organization-member-dialog/add-organization-member-dialog.component";
import { ConfirmationDialogComponent } from "./common/dialogs/confirmation-dialog/confirmation-dialog.component";
import { EditUserDialogComponent } from "./common/dialogs/edit-user-dialog/edit-user-dialog.component";
import { AgentConfigurationComponent } from "./pages/cluster/agent-configuration/agent-configuration.component";
import { AgentsConfigurationComponent } from "./pages/cluster/agents-configuration/agents-configuration.component";
import {
  ClusterDismissibleNoticeComponent
} from "./common/components/dismissible-notice/cluster-dismissible-notice/cluster-dismissible-notice.component";
import { CostsComponent } from "./pages/cluster/costs/costs.component";
import { PluginConfigurationComponent } from "./pages/cluster/plugin-configuration/plugin-configuration.component";
import { PluginsConfigurationComponent } from "./pages/cluster/plugins-configuration/plugins-configuration.component";
import { TotalCostsComponent } from "./pages/cluster/total-costs/total-costs.component";
import { ClusterConfigurationComponent } from "./pages/cluster/cluster-configuration/cluster-configuration.component";
import { TotalClusterCostsPipe } from "./common/pipes/total-cluster-costs..pipe";
import { MathCeilingPipe } from "./common/pipes/math-ceiling.pipe";
import { AgentDetailsComponent } from "./pages/cluster/agent-details/agent-details.component";
import { PluginDetailsComponent } from "./pages/cluster/plugin-details/plugin-details.component";
import { HasErrorPipe } from "./common/pipes/has-error.pipe";

const MAT_DIALOG_CONFIG: MatDialogConfig = {
  // Enable backdrop
  hasBackdrop: true,
  // See `mat-dialog.scss`
  backdropClass: 'dialog-backdrop-blur',
  // Close dialogs on navigation
  closeOnNavigation: true,
};

export function initializeGA(gaService: GtagService) {
  return () => gaService;  // Ensures the service initializes when the app starts
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    NgxStripeModule.forRoot(),
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatSliderModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectCountryModule.forRoot('en'),
    MatSelectModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatSliderModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    ToastrModule.forRoot({
      toastComponent: ToastComponent,
      extendedTimeOut: 0,
      tapToDismiss: false,
    }),
  ],
  declarations: [
    AppComponent,
    RegistrationComponent,
    ClusterDetailsComponent,
    BillingComponent,
    UpgradeComponent,
    LoginComponent,
    VerifyComponent,
    AbsolutePipe,
    ExtractBalancePipe,
    ClusterListComponent,
    RequireVerificationDialogComponent,
    RequestAgentQuotaDialogComponent,
    PasswordResetComponent,
    ClusterStatusComponent,
    CalcTimePipe,
    CreateClusterComponent,
    InvoiceDetailsComponent,
    ToastComponent,
    SpinnerDialogComponent,
    PasswordResetDialogComponent,
    SvgIconComponent,
    SpinnerComponent,
    AuthFooterComponent,
    SidebarLayoutComponent,
    SidebarProfileComponent,
    ClusterStatusIconComponent,
    SettingsComponent,
    ButtonComponent,
    DialogHeaderComponent,
    DialogBodyComponent,
    AddPaymentDialogComponent,
    AddPaymentWizardDialogComponent,
    AddPaymentWizardStepComponent,
    DismissibleNoticeComponent,
    StripeDismissibleNoticeComponent,
    PaymentsDismissibleNoticeComponent,
    BillingDetailsStepComponent,
    BillingAddressStepComponent,
    StripeCardInputWrapperComponent,
    SafeHTMLPipe,
    TabComponent,
    TabsetComponent,
    SettingsComponent,
    UserPersonalInfoSettingsComponent,
    UserChangePasswordSettingsComponent,
    OrganizationAccountSettingsComponent,
    OrganizationAddressSettingsComponent,
    OrganizationMembersSettingsComponent,
    PaymentCardSettingsComponent,
    AddressSettingsComponent,
    CountrySelectComponent,
    AsFormControlPipe,
    SkeletonLoaderComponent,
    CardBrandComponent,
    CardDetailsComponent,
    CardSettingsComponent,
    PasswordVisibilityComponent,
    CloseAccountDialogComponent,
    AddOrganizationMemberDialogComponent,
    ConfirmationDialogComponent,
    EditUserDialogComponent,
    AgentConfigurationComponent,
    AgentsConfigurationComponent,
    ClusterDismissibleNoticeComponent,
    CostsComponent,
    PluginConfigurationComponent,
    PluginsConfigurationComponent,
    TotalCostsComponent,
    ClusterConfigurationComponent,
    TotalClusterCostsPipe,
    MathCeilingPipe,
    AgentDetailsComponent,
    PluginDetailsComponent,
    CostsSwitcherComponent,
    OnHoldDialogComponent,
    WakeClusterComponent,
    MaintenanceComponent,
    LabelComponent,
    HasErrorPipe
  ],
  providers: [
    simulatedBackendProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: MAT_DIALOG_CONFIG,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeGA,
      deps: [GtagService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor() {
    // Register Swiper Element
    register();
  }
}

<div class="flex gap-6 pb-6">
  <div class="flex-1">
    <h1 class="text-3xl leading-3xl font-medium text-gray-900 pb-1">Create a new Step cluster</h1>

    <p class="text-sm leading-sm text-gray-500">
      The cluster is automatically stopped when not in use and automatically started when opening the cluster URI to
      prevent unintended costs. Manually stopping it is possible as well.
      <app-svg-icon
        name="info-circle"
        matTooltip="After creation you can configure Auto Start/Stops grace period or disable it completely (default is 30 minutes)."
        class="text-primary-600 text-lg h-5 inline-flex items-center align-bottom"
      />
    </p>

    <div class="pt-5">
      <hr />
    </div>
  </div>

  <div class="self-end">
    <app-skeleton-loader
      [loading]="loading"
      [rounding]="SkeletonLoaderRounding.ROUNDED_LG"
      [withBorder]="true"
      borderWidth="1px"
    >
      <app-total-costs
        [customerPrices]="customerPrices"
        [agentsControlValue]="formGroup.controls.agents.value"
        [pluginTypesControlValue]="formGroup.controls.pluginTypes.value"
        [billingPeriodTypeControl]="formGroup.controls.billingPeriodType | asFormControl"
      />
    </app-skeleton-loader>
  </div>
</div>

<form [formGroup]="formGroup" (submit)="onSubmit()" class="flex flex-col gap-2">
  <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_LG">
    <app-cluster-configuration
      [billingPeriod]="billingPeriod[formGroup.controls.billingPeriodType.value]"
      [customerPrices]="customerPrices"
      [nameControl]="formGroup.controls.clusterName | asFormControl"
    />
  </app-skeleton-loader>

  @if (ENABLE_PLUGINS) {
    <div class="py-5">
      <hr />
    </div>

    <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_LG">
      <app-plugins-configuration
        [billingPeriod]="billingPeriod[formGroup.controls.billingPeriodType.value]"
        [customerPrices]="customerPrices"
        [pluginTypesControl]="formGroup.controls.pluginTypes | asFormControl"
      />
    </app-skeleton-loader>
  }

  <div class="py-5">
    <hr />
  </div>

  <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_LG">
    <app-agents-configuration
      [billingPeriod]="billingPeriod[formGroup.controls.billingPeriodType.value]"
      [customerPrices]="customerPrices"
      [agentsControl]="formGroup.controls.agents | asFormControl"
      [maxQuota]="maxQuota"
    />
  </app-skeleton-loader>

  <div class="py-5">
    <hr />
  </div>

  <div class="flex justify-end">
    <div class="flex gap-3">
      <app-button
        [buttonType]="ButtonType.SUBMIT"
        [buttonStyle]="ButtonStyle.BTN_0"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        [disabled]="loading"
      >
        Create cluster
      </app-button>
    </div>
  </div>
</form>

import { Component } from '@angular/core';
import { DismissibleNoticeComponent, DismissibleNoticeStyle } from "../dismissible-notice.component";
import { SvgIconComponent } from "../../svg-icon/svg-icon.component";
import { RouterLink } from "@angular/router";

@Component({
  selector: 'app-cluster-dismissible-notice',
  templateUrl: './cluster-dismissible-notice.component.html',
  styleUrls: ['./cluster-dismissible-notice.component.scss'],
})
export class ClusterDismissibleNoticeComponent {
  readonly DismissibleNoticeStyle = DismissibleNoticeStyle;
}

import { Component } from '@angular/core';
import { SpinnerComponent } from "../../components/spinner/spinner.component";

@Component({
  selector: 'app-spinner-dialog',
  templateUrl: './spinner-dialog.component.html',
  styleUrls: ['./spinner-dialog.component.scss'],
})
export class SpinnerDialogComponent {
  message: string;

  setMessage(message: string) {
    this.message = message;
  }
}

import { Component, inject, OnInit } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from '@angular/material/dialog';
import { AccountService } from 'src/app/common/services/account.service';
import {
  ButtonComponent,
  ButtonPadding,
  ButtonStyle,
  ButtonType
} from "src/app/common/components/button/button.component";
import { HasErrorPipe } from "../../pipes/has-error.pipe";
import { MatError, MatFormField, MatFormFieldModule } from "@angular/material/form-field";
import { LabelComponent } from "../../components/label/label.component";
import { SvgIconComponent } from "../../components/svg-icon/svg-icon.component";
import { DialogBodyComponent } from "../../components/dialog-body/dialog-body.component";
import { DialogHeaderComponent } from "../../components/dialog-header/dialog-header.component";
import { MatInputModule } from "@angular/material/input";

@Component({
  selector: 'app-add-organization-member-dialog',
  templateUrl: './add-organization-member-dialog.component.html',
  styleUrls: ['./add-organization-member-dialog.component.scss'],
})
export class AddOrganizationMemberDialogComponent implements OnInit {
  private _formBuilder = inject(UntypedFormBuilder);
  private _accountService = inject(AccountService);
  private _matDialogRef = inject(MatDialogRef<AddOrganizationMemberDialogComponent>);

  readonly formGroup = this._formBuilder.group({
    fullName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  loading: boolean = false;
  disableClose: boolean = false;
  organizationName?: string;

  ngOnInit(): void {
    this.organizationName = this._accountService.organization()?.name;
  }

  onCancel(): void {
    if (this.disableClose) {
      return;
    }

    this._matDialogRef.close(false);
  }

  onSubmit(): void {
    if (this.loading) {
      return;
    }

    if (this.formGroup.invalid) {
      return;
    }

    this.loading = true;
    this.disableClose = true;
    this._matDialogRef.disableClose = true;

    this._accountService.addUser(this.formGroup.value).subscribe({
      next: () => {
        this.loading = false;
        this._matDialogRef.close(true);
      },
      error: () => {
        this.loading = false;
        this.disableClose = false;
        this._matDialogRef.disableClose = false;
      },
    });
  }
}

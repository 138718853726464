import { Component, inject, TemplateRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ButtonComponent,
  ButtonPadding,
  ButtonStyle,
  ButtonType
} from "src/app/common/components/button/button.component";
import { DialogBodyComponent } from "../../components/dialog-body/dialog-body.component";
import { DialogHeaderComponent } from "../../components/dialog-header/dialog-header.component";
import { CommonModule } from "@angular/common";

export interface ConfirmationDialogData {
  headingTemplate?: TemplateRef<{}>;
  headingContext?: Object;
  descriptionTemplate?: TemplateRef<{}>;
  descriptionContext?: Object;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  private _matDialogRef = inject(MatDialogRef<ConfirmationDialogComponent>);

  readonly _dialogData = inject(MAT_DIALOG_DATA);
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  onCancel(): void {
    this._matDialogRef.close(false);
  }

  onContinue(): void {
    this._matDialogRef.close(true);
  }
}

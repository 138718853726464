import { inject, Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from './account.service';
import { Organization } from "../models/organization";
import { RegistrationState } from "../models/registration-state.enum";

@Injectable({
  providedIn: 'root',
})
export class UnauthGuard  {
  private _accountService = inject(AccountService);
  private _router = inject(Router);

  canActivate(): Observable<boolean> {
    return this._accountService.isAuthenticated().pipe(
      map((isLoggedIn) => {
        if (isLoggedIn) {
          this._router.navigate(['/cluster']);
          return false;
        }

        return true;
      })
    );
  }

}

import { Component, Input } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { BillingPeriod, CustomerPrices } from 'src/app/common/models/billing';
import { CostsComponent } from "../costs/costs.component";
import { CurrencyPipe } from "@angular/common";
import { MatError, MatFormField, MatFormFieldModule } from "@angular/material/form-field";
import { HasErrorPipe } from "../../../common/pipes/has-error.pipe";
import { MatInputModule } from "@angular/material/input";

@Component({
  selector: 'app-cluster-configuration',
  templateUrl: './cluster-configuration.component.html',
  styleUrls: ['./cluster-configuration.component.scss'],
})
export class ClusterConfigurationComponent {
  @Input() billingPeriod!: BillingPeriod;
  @Input() customerPrices?: CustomerPrices;
  @Input() nameControl = new UntypedFormControl('');
}

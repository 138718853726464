<div class="p-4 border border-gray-200 rounded-lg flex items-center gap-3">
  <div  class="w-8">
    <span class="font-mono font-semibold text-gray-700">{{ agentCount }} x</span>
  </div>
  <div class="w-12 h-8 bg-white border border-gray-100 rounded-md px-2 py-1">
    <img [src]="agent.imgSrc" [alt]="agent.imgAlt" class="w-full h-full" />
  </div>

  <div class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">
    {{ agent.title }}
    <app-svg-icon name="help-circle" [matTooltip]="agent.description" class="text-gray-400"/>
  </div>
</div>

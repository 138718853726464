import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { BillingPeriod, CustomerPrices } from 'src/app/common/models/billing';
import { Agent, AgentType } from 'src/app/common/services/agents.token';
import { CostsComponent } from "../costs/costs.component";
import { CurrencyPipe } from "@angular/common";
import { MatSlider, MatSliderModule } from "@angular/material/slider";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { AsFormControlPipe } from "../../../common/pipes/as-form-control.pipe";

@Component({
  selector: 'app-agent-configuration',
  templateUrl: './agent-configuration.component.html',
  styleUrls: ['./agent-configuration.component.scss'],
})
export class AgentConfigurationComponent {
  @Input() agent!: Agent;
  @Input() billingPeriod!: BillingPeriod;
  @Input() customerPrices?: CustomerPrices;
  @Input() sliderControl = new UntypedFormControl(1);
  @Input() remainingQuota!: number;

  @Output() agentRemove = new EventEmitter<AgentType>();
}

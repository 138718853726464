import { AfterViewInit, Component, inject, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort } from "@angular/material/sort";
import {
  MatFooterRowDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRowDef,
  MatTable,
  MatTableDataSource, MatTableModule
} from "@angular/material/table";
import { ClusterListItem, ClusterStatus } from '../../common/models/cluster';
import { PortalService } from '../../common/services/portal.service';
import {
  ButtonComponent,
  ButtonPadding,
  ButtonStyle,
  ButtonType
} from "../../common/components/button/button.component";
import {
  SkeletonLoaderComponent,
  SkeletonLoaderRounding
} from "../../common/components/skeleton-loader/skeleton-loader.component";
import {
  ClusterDismissibleNoticeComponent
} from "../../common/components/dismissible-notice/cluster-dismissible-notice/cluster-dismissible-notice.component";
import { MathCeilingPipe } from "../../common/pipes/math-ceiling.pipe";
import { SvgIconComponent } from "../../common/components/svg-icon/svg-icon.component";
import { NgClass } from "@angular/common";
import { RouterLink } from "@angular/router";
import { ClusterStatusComponent } from "../../common/components/cluster-status/cluster-status.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
  selector: 'app-cluster',
  templateUrl: './cluster-list.component.html',
  styleUrls: ['./cluster-list.component.scss'],
})
export class ClusterListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;

  private _portalService = inject(PortalService);

  readonly clustersTableColumns: string[] = ['name', 'status', 'actions'];
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly ClusterStatus = ClusterStatus;
  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;

  loading: boolean = false;
  clusters: ClusterListItem[] = [];
  clustersDataSource = new MatTableDataSource<ClusterListItem>([]);
  clustersExist: boolean = true;
  statusChangeOngoingByCluster: Record<string, boolean> = {};
  lastSort?: Sort;

  ngOnInit(): void {
    this.loading = true;
    this._portalService.getClusterList().subscribe({
      next: (clusters) => {
        this.clusters = clusters;
        this.clustersExist = !!clusters.length;
        this.clustersDataSource.data = clusters;

        if (this.lastSort) {
          this.onSortChange(this.lastSort);
        }

        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  ngAfterViewInit(): void {
    this.clustersDataSource.paginator = this.paginator;
  }

  onSortChange(sort: Sort): void {
    this.lastSort = sort;

    const clustersClone = this.clusters.slice();

    if (!sort.direction) {
      this.clustersDataSource.data = clustersClone;
      return;
    }

    const priorityByClusterStatus: Record<ClusterStatus, number> = {
      [ClusterStatus.CREATING]: 2,
      [ClusterStatus.CREATION_FAILED]: 1,
      [ClusterStatus.RUNNING]: 0,
      [ClusterStatus.STOPPING]: 2,
      [ClusterStatus.STOPPED]: 3,
      [ClusterStatus.STARTING]: 2,
      [ClusterStatus.DESTROYING]: 2,
      [ClusterStatus.DESTROYED]: 3,
      [ClusterStatus.ERROR]: 1,
      [ClusterStatus.IDLE]: 3,
      [ClusterStatus.UPGRADING]: 2,
    };

    const compareFn = (a: ClusterListItem, b: ClusterListItem): number => {
      switch (sort.active) {
        case 'name':
          return a.name.localeCompare(b.name);

        case 'status':
          return priorityByClusterStatus[a.state] - priorityByClusterStatus[b.state];

        default:
          return 0;
      }
    };

    switch (sort.direction) {
      case 'asc':
        this.clustersDataSource.data = clustersClone.sort((a, b) => compareFn(a, b));
        break;

      case 'desc':
        this.clustersDataSource.data = clustersClone.sort((a, b) => compareFn(b, a));
        break;
    }
  }

  startCluster(cluster: ClusterListItem): void {
    this.statusChangeOngoingByCluster[cluster.id] = true;

    const initialClusterState = cluster.state;

    cluster.state = ClusterStatus.STARTING;

    this._portalService.startCluster(cluster.id).subscribe({
      next: () => {
        this.statusChangeOngoingByCluster[cluster.id] = false;
      },
      error: () => {
        cluster.state = initialClusterState;
        this.statusChangeOngoingByCluster[cluster.id] = false;
      },
    });
  }

  stopCluster(cluster: ClusterListItem): void {
    this.statusChangeOngoingByCluster[cluster.id] = true;

    const initialClusterState = cluster.state;

    cluster.state = ClusterStatus.STOPPING;

    this._portalService.stopCluster(cluster.id).subscribe({
      next: () => {
        this.statusChangeOngoingByCluster[cluster.id] = false;
      },
      error: () => {
        cluster.state = initialClusterState;
        this.statusChangeOngoingByCluster[cluster.id] = false;
      },
    });
  }
}

<aside class="p-8 w-[28rem] flex flex-col bg-primary-900">
  <img src="/assets/images/portal-logo.png" alt="Step Portal logo" class="px-4 pb-6 self-start" />

  <ul class="flex-1 flex flex-col gap-2">
    <li>
      <app-add-payment-wizard-step
        title="Step 1"
        description="Add your billing address"
        [active]="activeStep === billingAddressStep"
        [hasNext]="true"
        [complete]="billingAddressComplete"
        (stepClick)="setActiveStep(billingAddressStep)"
      />
    </li>

    <li>
      <app-add-payment-wizard-step
        title="Step 2"
        description="Add your billing details"
        [active]="activeStep === billingDetailsStep"
        [complete]="billingDetailsComplete"
        (stepClick)="setActiveStep(billingDetailsStep)"
      />
    </li>
  </ul>

  <div class="text-primary-200 text-sm leading-sm flex items-center justify-between">
    <span>© Step 2024</span>

    <a href="https://step.dev/contact/" class="flex items-center gap-2 focus:outline-none focus:text-primary-500">
      <app-svg-icon name="mail-01"/>
      Contact
    </a>
  </div>
</aside>

<main class="flex-1 min-w-0 flex flex-col relative">
  <button
    type="button"
    matRipple
    matDialogClose
    class="absolute top-7 right-7 z-[2] rounded-full p-3.5 focus-ring focus-ring-primary-0"
  >
    <app-svg-icon name="x-close" class="text-gray-500 text-xs"/>
  </button>

  <swiper-container #swiperContainer (click)="onSwiperContainerClick()" class="w-full">
    <swiper-slide class="pt-8 px-8" [attr.tabindex]="activeStep === billingAddressStep ? null : -1">
      <app-billing-address-step (stepComplete)="onAddressStepComplete()"/>
    </swiper-slide>

    <swiper-slide class="pt-8 px-8" [attr.tabindex]="activeStep === billingDetailsStep ? null : -1">
      <app-billing-details-step (stepComplete)="onDetailsStepComplete()"/>
    </swiper-slide>
  </swiper-container>

  <div class="px-8 flex-1 flex flex-col">
    <app-stripe-dismissible-notice/>
    <app-payments-dismissible-notice/>

    <div class="py-4 flex justify-center mt-auto">
      <div class="flex gap-3">
        <button
          type="button"
          (click)="setActiveStep(billingAddressStep)"
          class="h-2 rounded-[0.25rem] w-[11rem] bg-gray-100 focus-ring-primary-1 overflow-hidden"
        >
          <div
            [ngClass]="{
              'translate-x-0': activeStep === billingAddressStep,
              'translate-x-[11.75rem]': activeStep === billingDetailsStep,
              'transition-all duration-300': paginationTransition
            }"
            class="h-full rounded-[0.25rem] bg-primary-700"
          ></div>
        </button>

        <button
          type="button"
          (click)="setActiveStep(billingDetailsStep)"
          class="h-2 rounded-[0.25rem] w-[11rem] bg-gray-100 focus-ring-primary-1 overflow-hidden"
        >
          <div
            [ngClass]="{
              '-translate-x-[11.75rem]': activeStep === billingAddressStep,
              'translate-x-0': activeStep === billingDetailsStep,
              'transition-all duration-300': paginationTransition
            }"
            class="h-full rounded-[0.25rem] bg-primary-700"
          ></div>
        </button>
      </div>
    </div>
  </div>
</main>

import { Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { Subject, filter, switchMap, tap } from 'rxjs';
import { AddOrganizationMemberDialogComponent } from 'src/app/common/dialogs/add-organization-member-dialog/add-organization-member-dialog.component';
import { ConfirmationDialogData } from 'src/app/common/dialogs/confirmation-dialog/confirmation-dialog.component';
import { EditUserDialogComponent } from 'src/app/common/dialogs/edit-user-dialog/edit-user-dialog.component';
import { User } from 'src/app/common/models/user';
import { AccountService } from 'src/app/common/services/account.service';
import { ConfirmationService } from 'src/app/common/services/confirmation.service';
import { ButtonComponent, ButtonPadding, ButtonStyle, ButtonType } from "../../button/button.component";
import { SkeletonLoaderComponent, SkeletonLoaderRounding } from "../../skeleton-loader/skeleton-loader.component";
import { SvgIconComponent } from "../../svg-icon/svg-icon.component";
import { MatTable, MatTableModule } from "@angular/material/table";
import { NgClass } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

interface DescriptionTemplateContext {
  $implicit: User;
}

@Component({
  selector: 'app-organization-members-settings',
  templateUrl: './organization-members-settings.component.html',
  styleUrls: ['./organization-members-settings.component.scss'],
})
export class OrganizationMembersSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('headingTemplate') headingTemplate: TemplateRef<{}>;
  @ViewChild('descriptionTemplate') descriptionTemplate: TemplateRef<DescriptionTemplateContext>;

  private _accountService = inject(AccountService);
  private _matDialog = inject(MatDialog);
  private _confirmationService = inject(ConfirmationService);

  private refreshUsers$ = new Subject<void>();

  readonly tableColumns: string[] = ['name', 'role', 'actions'];
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;

  loading: boolean = false;
  users: User[] = [];

  ngOnInit(): void {
    this.refreshUsers$
      .pipe(
        tap(() => {
          this.loading = true;
        }),
        switchMap(() => this._accountService.getUsers())
      )
      .subscribe({
        next: (users) => {
          this.users = users;
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });

    this.refreshUsers$.next();
  }

  ngOnDestroy(): void {
    this.refreshUsers$.complete();
  }

  onDelete(user: User): void {
    const dialogData: ConfirmationDialogData = {
      headingTemplate: this.headingTemplate,
      descriptionTemplate: this.descriptionTemplate,
      descriptionContext: {
        $implicit: user,
      },
    };

    this._confirmationService
      .confirm(dialogData)
      .pipe(
        filter((confirmed) => confirmed),
        tap(() => {
          this.loading = true;
        }),
        switchMap(() => this._accountService.deleteUser(user.id))
      )
      .subscribe({
        next: () => {
          this.users = this.users.filter(({ id }) => id !== user.id);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  onEdit(user: User): void {
    this._matDialog
      .open(EditUserDialogComponent, { data: { user } })
      .afterClosed()
      .subscribe((userModified) => {
        if (userModified) {
          this.refreshUsers$.next();
        }
      });
  }

  onAddMember(): void {
    const dialogRef = this._matDialog.open(AddOrganizationMemberDialogComponent);

    dialogRef.afterClosed().subscribe((userAdded) => {
      if (userAdded) {
        this.refreshUsers$.next();
      }
    });
  }
}

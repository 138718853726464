import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BillingPeriodType, CustomerPrices } from 'src/app/common/models/billing';
import { AgentsControlValue } from '../agents-configuration/agents-configuration.component';
import { PluginTypesControlValue } from '../plugins-configuration/plugins-configuration.component';
import { CostsSwitcherComponent } from "../costs-switcher/costs-switcher.component";
import { SvgIconComponent } from "../../../common/components/svg-icon/svg-icon.component";

@Component({
  selector: 'app-total-costs',
  templateUrl: './total-costs.component.html',
  styleUrls: ['./total-costs.component.scss'],
})
export class TotalCostsComponent {
  @Input() customerPrices?: CustomerPrices;
  @Input() agentsControlValue!: AgentsControlValue;
  @Input() pluginTypesControlValue!: PluginTypesControlValue;
  @Input() billingPeriodTypeControl = new UntypedFormControl(BillingPeriodType.HOUR);
}

<form [formGroup]="formGroup" (submit)="onSubmit()">
  <div class="flex justify-between">
    <h1 class="text-3xl leading-3xl font-medium text-gray-900">{{ cluster?.name }}</h1>

    @if (!editMode) {
      <app-button
        [buttonType]="ButtonType.BUTTON"
        [buttonStyle]="ButtonStyle.BTN_1"
        [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        [disabled]="loading"
        (buttonClick)="onToggleEditMode()"
      >
        Edit cluster
      </app-button>
    } @else {
      <div class="flex gap-3">
        <app-button
          [buttonType]="ButtonType.BUTTON"
          [buttonStyle]="ButtonStyle.BTN_1"
          [buttonPadding]="ButtonPadding.BTN_PADDING_0"
          (buttonClick)="onCancel()"
        >
          Cancel
        </app-button>

        <app-button
          [buttonType]="ButtonType.SUBMIT"
          [buttonStyle]="ButtonStyle.BTN_0"
          [buttonPadding]="ButtonPadding.BTN_PADDING_0"
        >
          Update cluster
        </app-button>
      </div>
    }
  </div>

  <div class="py-5">
    <hr/>
  </div>

  <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
    <div class="flex gap-8">
      <div class="flex-[2]">
        <div class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">
          Status
          <app-svg-icon
            name="info-circle"
            matTooltip="The current state of your cluster. You will only be charged for usage while in 'running' state"
          />
        </div>
      </div>

      <div class="flex-[4] flex items-center gap-2">
        @if (cluster) {
          <app-cluster-status [clusterStatus]="cluster.state"/>

          <button
            type="button"
            [disabled]="
              cluster.state === ClusterStatus.RUNNING ||
              cluster.state === ClusterStatus.STARTING ||
              cluster.state === ClusterStatus.STOPPING ||
              statusChangeOngoingByCluster[cluster.id]
            "
            matRipple
            matTooltip="Start cluster"
            (click)="startCluster(cluster)"
            class="focus:outline-none focus:text-primary-500 disabled:text-gray-300 p-2 rounded-full text-gray-500 text-lg"
          >
            <app-svg-icon name="play-circle"/>
          </button>

          <button
            type="button"
            [disabled]="
              cluster.state === ClusterStatus.STOPPED ||
              cluster.state === ClusterStatus.STOPPING ||
              cluster.state === ClusterStatus.STARTING ||
              statusChangeOngoingByCluster[cluster.id]
            "
            matRipple
            matTooltip="Stop cluster"
            (click)="stopCluster(cluster)"
            class="focus:outline-none focus:text-primary-500 disabled:text-gray-300 p-2 rounded-full text-gray-500 text-lg"
          >
            <app-svg-icon name="stop-circle"/>
          </button>
        }
      </div>
    </div>
  </app-skeleton-loader>

  <div class="py-5">
    <hr/>
  </div>

  <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
    <div class="flex gap-8">
      <div class="flex-[2]">
        <div class="text-sm leading-sm font-medium text-gray-700">Cluster URL</div>
      </div>

      <div class="flex-[4]">
        <a [href]="cluster?.url" target="_blank" class="text-sm leading-sm link-0">
          {{ cluster?.url }}
        </a>
      </div>
    </div>
  </app-skeleton-loader>

  <div class="py-5">
    <hr/>
  </div>

  <div class="flex gap-8">
    <div class="flex-[2]">
      <div class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">
        Credentials
        <app-svg-icon
          name="info-circle"
          matTooltip="These are the credentials of the cluster, note that if you have multiple cluster, each has its own credentials."
        />
      </div>
    </div>

    <div class="flex-[4]">
      <app-skeleton-loader [loading]="loading || (clusterPasswordById[clusterId!] && !clusterPasswordById[clusterId!].clusterPasswordInitialized)"
                           [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
        <div class="flex-[4] flex items-center gap-4">
          <mat-form-field subscriptSizing="dynamic">
            <app-svg-icon name="user-circle" matPrefix/>
            <input matInput type="text" [formControl]="formGroup.controls.username | asFormControl"/>
          </mat-form-field>

          @if ((clusterId && clusterPasswordById[clusterId!] && clusterPasswordById[clusterId!].clusterPassword) || cluster?.state === ClusterStatus.STARTING) {
          <mat-form-field subscriptSizing="dynamic" class="with-suffix">
            <app-svg-icon name="lock-04" matPrefix/>
            <input #passwordInput matInput type="password" [formControl]="formGroup.controls.password | asFormControl"/>
            <app-password-visibility [inputElement]="passwordInput" matSuffix/>
          </mat-form-field>
          } @else {
            <div class="text-sm leading-sm text-gray-500 flex items-center gap-2">
              Cluster password no longer available
              <app-svg-icon
                name="info-circle"
                matTooltip="For security reasons the password is only available in the session in which the cluster was created."
              />
            </div>
            @if (clusterPasswordById && clusterPasswordById[clusterId!] && clusterPasswordById[clusterId!].clusterPasswordInitialized) {
              <app-button
                [buttonType]="ButtonType.BUTTON"
                [buttonStyle]="ButtonStyle.BTN_1"
                [buttonPadding]="ButtonPadding.BTN_PADDING_0"
                [disabled]="cluster?.state !== ClusterStatus.RUNNING"
                (buttonClick)="resetClusterPassword()"
                [matTooltip]="cluster?.state !== ClusterStatus.RUNNING ? 'The cluster must be running to reset its password' : 'Resets the clusters password and shows it until this session ends'"
              >
                Reset password
              </app-button>
            }
          }
          @if ((clusterId && clusterPasswordById[clusterId!] && clusterPasswordById[clusterId!].clusterPassword) || cluster?.state === ClusterStatus.STARTING) {
            <app-svg-icon
              class="large"
              name="info-circle"
              matTooltip="For security reasons the password is only available in the session in which the cluster was created."
            />
          }
        </div>
      </app-skeleton-loader>
    </div>
  </div>

  <div class="py-5">
    <hr/>
  </div>

  <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
    <div class="flex gap-8">
      <div class="flex-[2]">
        <div class="text-sm leading-sm font-medium text-gray-700">Permanent Agents</div>
      </div>

      <div class="flex-[4] flex flex-col gap-3">
        @if (!editMode) {
          @if (cluster?.agentPools?.length && cluster!.agentPools!.length > 0) {
            @for (agentPool of cluster?.agentPools; track agentPool.key) {
              @if (agentPool.configured) {
                @if (agentByType[agentPool.key]; as agent) {
                  <app-agent-details [agentCount]="agentPool.configured" [agent]="agent"/>
                }
              }
            }
          } @else {
            <div class="text-sm leading-sm text-gray-500">No permanent agents are configured, but Step will automatically provision agents when needed (<a href="https://step.dev/knowledgebase/userdocs/agent-provisioning/" class="read-more" target="_blank">read more</a>). Edit cluster to add
              permanent agents.
            </div>
          }
        } @else {
          <app-agents-configuration
            [billingPeriod]="billingPeriod[billingPeriodTypeControl.value]"
            [customerPrices]="customerPrices"
            [agentsControl]="formGroup.controls.agents | asFormControl"
            [maxQuota]="maxQuota"
          />
        }
      </div>
    </div>
  </app-skeleton-loader>

  @if (ENABLE_PLUGINS) {
    <div class="py-5">
      <hr/>
    </div>

    <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
      <div class="flex gap-8">
        <div class="flex-[2]">
          <div class="text-sm leading-sm font-medium text-gray-700">Plugins</div>
        </div>

        <div class="flex-[4] flex flex-col gap-3">
          @if (cluster?.plugins?.length && cluster!.plugins!.length > 0) {
            @for (plugin of cluster?.plugins; track plugin.key) {
              @if (pluginByType[plugin.key]; as plugin) {
                <app-plugin-details [plugin]="plugin"/>
              }
            }
          } @else {
            <div class="text-sm leading-sm text-gray-500">No plugins were added to this cluster. Plugins can only be added
              on cluster creation.
            </div>
          }
        </div>
      </div>
    </app-skeleton-loader>
  }

  <div class="py-5">
    <hr/>
  </div>

  <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
    <div class="flex gap-8">
      <div class="flex-[2]">
        <div class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">
          Current cost
          <app-svg-icon
            name="info-circle"
            matTooltip="The cost of this cluster with the current agent and plugin configuration. Costs are only charged while the cluster is in 'running' state"
          />
        </div>
      </div>

      <div class="flex-[4] flex items-center gap-1">
        <app-costs-switcher
          [customerPrices]="customerPrices"
          [agentsControlValue]="formGroup.controls.agents.value"
          [pluginTypesControlValue]="formGroup.controls.pluginTypes.value"
          [billingPeriodTypeControl]="billingPeriodTypeControl"
        />
      </div>
    </div>
  </app-skeleton-loader>

  <div class="py-5">
    <hr/>
  </div>

  <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
    <div class="flex gap-8">
      <div class="flex-[2]">
        <div class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">
          Automatic start / stop cluster
          <app-svg-icon
            name="info-circle"
            matTooltip="Your Step cluster can automatically shutdown and start when you open its URL. This will safe costs but adds a short startup time when starting."
          />
        </div>
      </div>

      <div class="flex-[4]">
        @if (_accountService.user()?.registrationState !== RegistrationState.BILLABLE) {
          <mat-slide-toggle [checked]="true" [disabled]="true" />
          <p class="text-sm leading-sm font-medium text-gray-700">
            <a routerLink="/upgrade" class="link-0">Upgrade</a> to the Flexible Plan to disable this feature.
          </p>
        } @else {
          <mat-slide-toggle [formControl]="formGroup.controls.automaticStartStop | asFormControl"/>
        }
      </div>
    </div>
  </app-skeleton-loader>

  @if (formGroup.controls.automaticStartStop.value) {
    <div class="py-5">
      <hr/>
    </div>

    <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
      <div class="flex gap-8">
        <div class="flex-[2]">
          <div class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">
            Grace period in minutes
            <app-svg-icon
              name="info-circle"
              matTooltip="The grace period after which the cluster did not receive any input before it is shut down. (default: 30 min)"
            />
          </div>
        </div>

        <div class="flex-[4]">
          <mat-slider
            [disabled]="!editMode"
            [min]="5"
            [max]="300"
            [step]="5"
            [discrete]="false"
            class="w-full"
          ><input matSliderThumb [formControl]="formGroup.controls.gracePeriodInMinutes | asFormControl" #slider/></mat-slider>
          {{ slider.value }}
        </div>
      </div>
    </app-skeleton-loader>
  }

  <div class="py-5">
    <hr/>
  </div>

  <app-skeleton-loader [loading]="loading" [rounding]="SkeletonLoaderRounding.ROUNDED_MD">
    <div class="flex gap-8">
      <div class="flex-[2]">
        <div class="text-sm leading-sm font-medium text-gray-700">Danger zone - delete cluster</div>
      </div>

      <div class="flex-[4] flex items-center gap-4">
        <app-button
          [buttonType]="ButtonType.BUTTON"
          [buttonStyle]="ButtonStyle.BTN_2"
          [buttonPadding]="ButtonPadding.BTN_PADDING_0"
          (buttonClick)="deleteCluster()"
        >
          Delete cluster
        </app-button>
      </div>
    </div>
  </app-skeleton-loader>
</form>

<ng-template #headingTemplate>Are you sure?</ng-template>
<ng-template #descriptionTemplate>
  You are about to delete <strong>{{ cluster?.name }}</strong> cluster. Do you wish to continue?
</ng-template>

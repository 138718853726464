import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractBalance',
})
export class ExtractBalancePipe implements PipeTransform {
  transform(billing: any): any {
    if (billing?.ending_balance === undefined) {
      return '';
    }

    const balanceString = billing.currency.toUpperCase() + ' ' + (billing.ending_balance * -1) / 100;
    return balanceString;
  }
}

import { Component, inject, OnInit } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from "@angular/forms";
import { AccountService } from 'src/app/common/services/account.service';
import { ButtonPadding, ButtonStyle, ButtonType } from '../../button/button.component';
import { SvgIconComponent } from "../../svg-icon/svg-icon.component";
import { MatFormField, MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";

@Component({
  selector: 'app-user-personal-info-settings',
  templateUrl: './user-personal-info-settings.component.html',
  styleUrls: ['./user-personal-info-settings.component.scss'],
})
export class UserPersonalInfoSettingsComponent implements OnInit {
  private _formBuilder = inject(UntypedFormBuilder);
  private _accountService = inject(AccountService);
  readonly formGroup = this._formBuilder.group({
    organizationName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  ngOnInit() {
    this.onReset();
  }

  onReset(): void {
    if (!this._accountService.user() || !this._accountService.organization()) {
      return;
    }

    const { email } = this._accountService.user()!;
    const { name: organizationName } = this._accountService.organization()!;

    this.formGroup.reset({
      organizationName,
      email,
    });

    this.formGroup.disable();
  }
}

import { Component, inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from "@angular/router";
import { PasswordResetDialogComponent } from 'src/app/common/dialogs/password-reset-dialog/password-reset-dialog.component';
import { RequireVerificationDialogData } from 'src/app/common/dialogs/require-verification-dialog/require-verification-dialog-data.interface';
import { LoginNavigationState } from 'src/app/common/models/login-navigation-state.interface';
import { RegistrationState } from 'src/app/common/models/registration-state.enum';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import {
  ButtonPadding,
  ButtonStyle,
  ButtonType
} from "src/app/common/components/button/button.component";
import { RequireVerificationDialogComponent } from '../../../common/dialogs/require-verification-dialog/require-verification-dialog.component';
import { AccountService } from '../../../common/services/account.service';
import { Organization } from "../../../common/models/organization";
import { RedirectService } from "../../../common/services/redirect.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  private _router = inject(Router);
  private _dialog = inject(MatDialog);
  private _formBuilder = inject(UntypedFormBuilder);
  private _accountService = inject(AccountService);
  private _spinnerService = inject(SpinnerService);
  private _redirectService = inject(RedirectService);

  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly formGroup = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  constructor() {
    const navigationState = this.getNavigationState();

    if (navigationState) {
      this.formGroup.setValue(navigationState);
      this.onSubmit();
    }
  }

  private getNavigationState(): LoginNavigationState | undefined {
    const navigation = this._router.getCurrentNavigation();

    return navigation?.extras?.state as LoginNavigationState | undefined;
  }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this._spinnerService.show();

    const { email, password } = this.formGroup.value;

    this._accountService.login(email, password).subscribe({
      next: ({ organization }) => {
        if (organization?.registrationState === RegistrationState.NEW) {
          this._dialog.open<RequireVerificationDialogComponent, RequireVerificationDialogData, undefined>(
            RequireVerificationDialogComponent,
            {
              data: {
                email,
                password,
              },
            }
          );
        } else {
          if (this._redirectService.hasRedirect()) {
            this._router.navigateByUrl(this._redirectService.getRedirectUrl());
            this._redirectService.clearRedirectUrl();
          } else {
            this._router.navigate(['/cluster']);
          }
        }

        this._spinnerService.hide();
      },
      error: () => {
        this._spinnerService.hide();
      },
    });
  }

  showForgotPassword(): void {
    this._dialog.open(PasswordResetDialogComponent);
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  private readonly storageKey = 'redirectUrl';

  setRedirectUrl(url: string): void {
    localStorage.setItem(this.storageKey, url);
  }

  getRedirectUrl(): string {
    return localStorage.getItem(this.storageKey) || '';
  }

  hasRedirect(): boolean {
    return !!this.getRedirectUrl();
  }

  clearRedirectUrl(): void {
    localStorage.removeItem(this.storageKey);
  }
}

<h5 class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">Cluster</h5>

<p class="text-sm leading-sm text-gray-500 pb-5">
  You may select a unique name of your choosing.
</p>

<div class="flex items-start gap-5">
  <mat-form-field subscriptSizing="dynamic" class="flex-1">
    <input matInput placeholder="Cluster name" name="clusterName" type="text" [formControl]="nameControl" class="relative z-[1]" />
    @if (nameControl | hasError:'required') {
      <mat-error>Cluster name is required</mat-error>
    }
    @if (nameControl | hasError:'pattern') {
      <mat-error>
        Cluster name can't contain special characters or spaces
      </mat-error>
    }
  </mat-form-field>

  @if (customerPrices ?? { controller: { amount: 0 } }; as customerPrices) {
    @if ((customerPrices['controller'].amount / 100) * billingPeriod.minutes
    | currency : '' : '' : billingPeriod.decimalPlaces; as amount) {
      <app-costs [amount]="amount" [unit]="billingPeriod.unit"/>
    }
  }
</div>

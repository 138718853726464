import { Component, DestroyRef, inject, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, from } from 'rxjs';
import { catchError, concatMap, filter, finalize, mergeMap, switchMap, tap } from 'rxjs/operators';
import { OnHoldDialogComponent } from 'src/app/common/dialogs/on-hold-dialog/on-hold-dialog.component';
import { BillingPeriodType, CustomerPrices } from 'src/app/common/models/billing';
import { Agent, AGENTS, AgentType } from 'src/app/common/services/agents.token';
import { ConfirmationService } from 'src/app/common/services/confirmation.service';
import { Plugin, PLUGINS } from 'src/app/common/services/plugins.token';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import {
  ButtonPadding,
  ButtonStyle,
  ButtonType
} from "src/app/common/components/button/button.component";
import {
  SkeletonLoaderRounding
} from "src/app/common/components/skeleton-loader/skeleton-loader.component";
import { Cluster, ClusterStatus } from '../../../common/models/cluster';
import { BillingService } from '../../../common/services/billing.service';
import { PortalService } from '../../../common/services/portal.service';
import {
  AgentsControlValue
} from "../agents-configuration/agents-configuration.component";
import { PluginTypesControlValue } from '../plugins-configuration/plugins-configuration.component';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AccountService } from "../../../common/services/account.service";
import { RegistrationState } from "../../../common/models/registration-state.enum";

interface ClusterDetailsFormValue {
  agents: AgentsControlValue;
  pluginTypes: PluginTypesControlValue;
  automaticStartStop: boolean;
  gracePeriodInMinutes: number;
  username: string;
  password: string;
}

interface ClusterPassword {
  clusterPassword?: string;
  clusterPasswordInitialized?: boolean;
}

const DEFAULT_MAX_QUOTA = 10;

@Component({
  selector: 'app-cluster-details',
  templateUrl: './cluster-details.component.html',
  styleUrls: ['./cluster-details.component.scss'],
})
export class ClusterDetailsComponent implements OnInit {
  @ViewChild('headingTemplate') headingTemplate: TemplateRef<{}>;
  @ViewChild('descriptionTemplate') descriptionTemplate: TemplateRef<{}>;

  private _formBuilder = inject(UntypedFormBuilder);
  private _portalService = inject(PortalService);
  private _route = inject(ActivatedRoute);
  private _router = inject(Router);
  private _dialog = inject(MatDialog);
  private _confirmationService = inject(ConfirmationService);
  private _spinnerService = inject(SpinnerService);
  private _toastrService = inject(ToastrService);
  private _billingService = inject(BillingService);
  protected _accountService = inject(AccountService);
  private _agents = inject(AGENTS);
  private _plugins = inject(PLUGINS);

  private _destroyRef = inject(DestroyRef);

  readonly billingPeriodTypeControl = new UntypedFormControl(BillingPeriodType.HOUR);
  readonly formGroup = this._formBuilder.group({
    agents: [{} as AgentsControlValue],
    pluginTypes: [[] as PluginTypesControlValue],
    automaticStartStop: [false],
    gracePeriodInMinutes: [30],
    username: [''],
    password: [''],
  });
  readonly agentByType: Partial<Record<string, Agent>> = {};
  readonly pluginByType: Partial<Record<string, Plugin>> = {};
  readonly billingPeriod = this._billingService.PERIOD;
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
  readonly ClusterStatus = ClusterStatus;
  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;
  readonly ENABLE_PLUGINS = false; // disabled since no working plugins available at the moment
  readonly RegistrationState = RegistrationState;

  loading: boolean = false;
  editMode: boolean = false;
  maxQuota: number = DEFAULT_MAX_QUOTA;
  statusChangeOngoingByCluster: Record<string, boolean> = {};
  cluster?: Cluster;
  clusterPasswordById: {[id: string]: ClusterPassword} = {}; // this view is cached, so we save the password by id to prevent "flickering"
  clusterId?: string;
  customerPrices?: CustomerPrices;
  formValue?: ClusterDetailsFormValue;
  sliderValue = 0;

  ngOnInit(): void {
    this._agents.forEach((agent) => {
      this.agentByType[agent.type] = agent;
    });

    this._plugins.forEach((plugin) => {
      this.pluginByType[plugin.type] = plugin;
    });

    combineLatest([
      this._route.params.pipe(
        tap((params) => {
          this.loading = true;
          this.clusterId = params['id'];
        }),
        switchMap((params) =>
          combineLatest([
            this._portalService.getCluster(params['id'])
          ])
        )
      ),
      this._billingService.getQuota(),
      this._billingService.getCustomerPrices(),
    ])
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: ([[cluster], maxQuota, customerPrices]) => {
          // removing all agent pools with 0 configured agents
          const agentPools = cluster.agentPools.filter((agentPool) => agentPool.configured > 0);
          cluster.agentPools = agentPools;

          this.cluster = cluster;
          this.maxQuota = maxQuota;
          this.customerPrices = customerPrices;

          if (!this.editMode) {
            this.initFormValue(this.cluster!);
            this.initFormGroup(this.formValue!);
            this.formGroup.disable();
          }

          if (!this.clusterPasswordById[this.clusterId!]?.clusterPassword && this.cluster?.state !== ClusterStatus.STARTING && this.cluster?.state !== ClusterStatus.CREATING) {
            this._portalService.retrieveInitialClusterPassword(this.clusterId!).subscribe((password) =>
              this.updateClusterPassword(password));
          }

          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  private initFormValue(cluster: Cluster): void {
    const agents = cluster.agentPools?.reduce((agentsControlValue, agentPool) => {
      if (!agentPool.configured) {
        return agentsControlValue;
      }

      agentsControlValue[agentPool.key as AgentType] = agentPool.configured;

      return agentsControlValue;
    }, {} as AgentsControlValue);

    const pluginTypes = cluster.plugins?.map((plugin) => plugin.key) as PluginTypesControlValue;

    this.formValue = {
      agents,
      pluginTypes,
      automaticStartStop: cluster.autoStartStop,
      gracePeriodInMinutes: cluster.gracePeriodS / 60,
      username: 'admin',
      password: this.clusterPasswordById[this.clusterId!]?.clusterPassword || '',
    };
  }

  private initFormGroup(formValue: ClusterDetailsFormValue): void {
    this.formGroup.controls.agents.setValue(formValue.agents);
    this.formGroup.controls.pluginTypes.setValue(formValue.pluginTypes);
    this.formGroup.controls.automaticStartStop.setValue(formValue.automaticStartStop);
    this.formGroup.controls.gracePeriodInMinutes.setValue(formValue.gracePeriodInMinutes);
    this.formGroup.controls.username.setValue(formValue.username);
    this.formGroup.controls.password.setValue(formValue.password);
  }

  resetClusterPassword(): void {
    if (!this.clusterId) {
      console.error('Can\'t reset password for unknown cluster.');
      return;
    }

    this.clusterPasswordById[this.clusterId!].clusterPassword = '';
    this.clusterPasswordById[this.clusterId!].clusterPasswordInitialized = false;

    this._portalService.resetClusterPassword(this.clusterId).pipe(
      mergeMap(() => this._portalService.retrieveInitialClusterPassword(this.clusterId!))
    ).subscribe((password) =>
      this.updateClusterPassword(password)
    );
  }

  updateClusterPassword(password: string) {
    this.clusterPasswordById[this.clusterId!] = {clusterPassword: password, clusterPasswordInitialized: true}
    this.formValue!.password = password;
    this.formGroup.controls.password.setValue(this.formValue!.password);
  }

  onToggleEditMode(): void {
    this.editMode = true;
    this.formGroup.enable();
  }

  onCancel(): void {
    this.editMode = false;
    this.formGroup.reset(this.formValue);
    this.formGroup.disable();
  }

  onSubmit(): void {
    const { agents, automaticStartStop, gracePeriodInMinutes } = this.formGroup.value as ClusterDetailsFormValue;

    const agentsCountsUpdates = Object.keys(agents).map((agentType) =>
      this._portalService.setNumberOfAgents(this.clusterId!, agentType, agents[agentType as AgentType]!)
    );

    this._spinnerService.show();

    from(agentsCountsUpdates)
      .pipe(
        concatMap((updateObservable) => updateObservable),
        finalize(() => this._spinnerService.hide()),
        catchError((error) => {
          this._toastrService.error('Cluster could not be updated. Please try again later or contact us directly.');
          throw error;
        })
      )
      .subscribe({
        complete: () => {
          this._portalService
            .setAutoStartStop(this.clusterId!, automaticStartStop, gracePeriodInMinutes * 60)
            .subscribe(() => {
              this.onCancel();
              this._toastrService.success(`Cluster updated successfully`);
              this._portalService.refreshCluster(this.clusterId!);
            });
        },
      });
  }

  startCluster(cluster: Cluster): void {
    this.statusChangeOngoingByCluster[this.clusterId!] = true;

    const initialClusterState = cluster.state;

    cluster.state = ClusterStatus.STARTING;

    this._portalService.startCluster(this.clusterId!).subscribe({
      next: () => {
        this._portalService.refreshCluster(this.clusterId!);

        cluster.state = ClusterStatus.RUNNING;

        this.statusChangeOngoingByCluster[this.clusterId!] = false;
      },
      error: () => {
        this._portalService.refreshCluster(this.clusterId!);

        cluster.state = initialClusterState;

        this.statusChangeOngoingByCluster[this.clusterId!] = false;

        this._dialog.open(OnHoldDialogComponent);
      },
    });
  }

  stopCluster(cluster: Cluster): void {
    this.statusChangeOngoingByCluster[this.clusterId!] = true;

    const initialClusterState = cluster.state;

    cluster.state = ClusterStatus.STOPPING;

    this._portalService.stopCluster(this.clusterId!).subscribe({
      next: () => {
        this._portalService.refreshCluster(this.clusterId!);
        this.statusChangeOngoingByCluster[this.clusterId!] = false;
      },
      error: () => {
        this._portalService.refreshCluster(this.clusterId!);

        cluster.state = initialClusterState;
        this.statusChangeOngoingByCluster[this.clusterId!] = false;

        this._dialog.open(OnHoldDialogComponent);
      },
    });
  }

  deleteCluster(): void {
    this._confirmationService
      .confirm({
        headingTemplate: this.headingTemplate,
        descriptionTemplate: this.descriptionTemplate,
      })
      .pipe(
        filter((confirmed) => confirmed),
        tap(() => {
          this._spinnerService.show();
        }),
        switchMap(() => this._portalService.deleteCluster(this.clusterId!))
      )
      .subscribe({
        next: () => {
          this._portalService.refreshCluster(this.clusterId!);
          this._spinnerService.hide();
          this._router.navigate(['/cluster']);
          this._toastrService.success(`Cluster ${this.cluster!.name} was deleted successfully!`);
        },
        error: () => {
          this._toastrService.error(
            `Cluster ${this.cluster!.name} could not be deleted. Please try again later or contact us directly.`
          );
          this._spinnerService.hide();
        },
      });
  }
}

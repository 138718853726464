<div class="flex justify-center relative pb-4">
  <div class="bg-primary-100 rounded-full p-3">
    <app-svg-icon [name]="icon" class="text-primary-600"/>
  </div>

  <button
    type="button"
    (click)="dialogClose.emit()"
    matRipple
    class="absolute top-0 right-0 rounded-full p-3.5 focus-ring focus-ring-primary-0"
  >
    <app-svg-icon name="x-close" class="text-gray-500 text-xs"/>
  </button>
</div>

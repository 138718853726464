import { Component, inject } from "@angular/core";
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-on-hold-dialog',
  templateUrl: './on-hold-dialog.component.html',
  styleUrls: ['./on-hold-dialog.component.scss'],
})
export class OnHoldDialogComponent {
  private _matDialogRef = inject(MatDialogRef<OnHoldDialogComponent>);

  onCancel(): void {
    this._matDialogRef.close();
  }
}

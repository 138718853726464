<div class="w-56 max-w-xs flex flex-col items-center gap-6">
  <app-spinner class="text-6xl"/>

  @if (message) {
    <p class="text-lg text-center">
      {{ message }}
    </p>
  } @else {
    <p class="text-lg text-center">Loading...</p>
  }
</div>

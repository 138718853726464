import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { AccountService } from 'src/app/common/services/account.service';
import { CountriesService, Country } from 'src/app/common/services/countries.service';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import { AddressSettingsComponent, AddressSettingsFormValue } from '../../address-settings/address-settings.component';

const ORGANIZATION_ADDRESS_UPDATE_SUCCESS = 'Organization address updated successfully';

@Component({
  selector: 'app-organization-address-settings',
  templateUrl: './organization-address-settings.component.html',
  styleUrls: ['./organization-address-settings.component.scss'],
})
export class OrganizationAddressSettingsComponent implements OnInit {
  @ViewChild(AddressSettingsComponent) addressSettingsComponent: AddressSettingsComponent;

  countries: Country[] = [];
  loading: boolean = false;
  initialFormValue?: AddressSettingsFormValue;

  private _accountService = inject(AccountService);
  private _countriesService = inject(CountriesService);
  private _spinnerService = inject(SpinnerService);
  private _toastrService = inject(ToastrService);

  ngOnInit(): void {
    this.loading = true;

    forkJoin([this._countriesService.getCountries(), this._accountService.getAddress(), this._accountService.getOrganization()]).subscribe({
      next: ([countries, organizationAddress, organization]) => {
        const country = countries.find((country) => country.name === organizationAddress?.country);

        this.initialFormValue = {
          street: organizationAddress?.street,
          city: organizationAddress?.city,
          postalCode: organizationAddress?.postalCode,
          state: organizationAddress?.state,
          country: country ?? null,
          phoneNumber: organization?.phoneNumber,
        };
        this.countries = countries;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  onSubmit(formValue: AddressSettingsFormValue): void {
    this._spinnerService.show();

    const { street, city, postalCode, state, country, phoneNumber } = formValue;

    const addressData = {
      street,
      city,
      postalCode,
      state,
      country,
      phoneNumber,
    };

    this._accountService.updateOrganization(addressData).subscribe({
      next: () => {
        this._spinnerService.hide();
        this._toastrService.success(ORGANIZATION_ADDRESS_UPDATE_SUCCESS);
        this.initialFormValue = addressData;
        this.addressSettingsComponent.onReset();
      },
      error: () => {
        this._spinnerService.hide();
      },
    });
  }
}

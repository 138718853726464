import { Component, DestroyRef, inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { Subject, combineLatest, interval, startWith, switchMap, tap } from 'rxjs';
import { AddPaymentWizardDialogComponent } from '../../common/dialogs/add-payment-wizard-dialog/add-payment-wizard-dialog.component';
import { Billing } from '../../common/models/billing';
import { CardDetails } from '../../common/models/card-details';
import { BillingService, DataLine } from '../../common/services/billing.service';
import { SETTINGS_ACTIVE_TAB_QUERY_PARAM, SettingsTabs } from '../../common/components/settings/settings.component';
import {
  SkeletonLoaderRounding
} from "../../common/components/skeleton-loader/skeleton-loader.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AccountService } from "../../common/services/account.service";
import { RegistrationState } from '../../common/models/registration-state.enum';

const POLLING_INTERVAL = 10000;

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit, OnDestroy {
  private _billingService = inject(BillingService);
  protected _accountService = inject(AccountService);
  private _router = inject(Router);
  private _matDialog = inject(MatDialog);

  readonly billingTableColumns: string[] = ['description', 'quantity', 'price', 'amount'];
  readonly invoicesTableColumns: string[] = ['name', 'date', 'paid', 'total', 'action'];
  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;
  readonly RegistrationState = RegistrationState;

  private _destroyRef = inject(DestroyRef);
  private refreshCardDetails$ = new Subject<void>();

  loading: boolean = false;
  cardDetailsLoading: boolean = false;
  initialLoad: boolean = true;
  dataLines: DataLine[] = [];
  invoices: Billing[] = [];
  cardDetails?: CardDetails;
  currentInvoice?: any;

  ngOnInit(): void {
    this.loading = true;
    this.cardDetailsLoading = true;

    combineLatest([
      interval(POLLING_INTERVAL).pipe(
        startWith(0),
        switchMap(() => this._billingService.getBilling())
      ),
      this.refreshCardDetails$.pipe(
        tap(() => {
          this.loading = true;
        }),
        switchMap(() => this._billingService.getCardsDetails())
      ),
      this._billingService.getInvoices().pipe(
        tap(() => {
          this.cardDetailsLoading = true;
        })
      ),
    ])
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: ([
          currentInvoice,
          [cardDetails],
          invoices,
        ]) => {
          this.dataLines = currentInvoice.lines.data;
          this.currentInvoice = currentInvoice;
          this.cardDetails = cardDetails;
          this.invoices = invoices;
          this.loading = false;
          this.cardDetailsLoading = false;
          this.initialLoad = false;
          },
        error: (error) => {
          this.loading = false;
          this.cardDetailsLoading = false;
          console.log('error loading billing', error);

        },
      });

    this.refreshCardDetails$.next();
  }

  ngOnDestroy(): void {
    this.refreshCardDetails$.complete();
  }

  onEditPaymentMethod(): void {
    this._router.navigate(['/settings'], {
      queryParams: {
        [SETTINGS_ACTIVE_TAB_QUERY_PARAM]: SettingsTabs.PAYMENT_METHOD,
      },
    });
  }

  onAddPaymentMethod(): void {
    const dialogRef = this._matDialog.open(AddPaymentWizardDialogComponent, {
      // See `mat-dialog.scss`
      panelClass: 'dialog-full-screen',
    });

    dialogRef.afterClosed().subscribe((wizardCompleted) => {
      if (!wizardCompleted) {
        return;
      }

      this.refreshCardDetails$.next();
    });
  }
}

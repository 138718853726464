<div class="relative isolate">
  <ng-content></ng-content>

  @if (loading) {
    <ng-container>
      <div
        class="absolute inset-0 overflow-hidden z-[2]"
        [ngClass]="{
          'rounded-md': rounding === SkeletonLoaderRounding.ROUNDED_MD,
          'rounded-lg': rounding === SkeletonLoaderRounding.ROUNDED_LG,
          'border border-transparent': withBorder
        }"
        [style.border-width.px]="borderWidth"
      >
        <div
          class="absolute z-[1] inset-0 bg-white"
          [ngClass]="{
            'rounded-md': rounding === SkeletonLoaderRounding.ROUNDED_MD,
            'rounded-lg': rounding === SkeletonLoaderRounding.ROUNDED_LG
          }"
        ></div>

        <div
          class="absolute z-[2] inset-0 -translate-x-full animate-[shimmer_2s_infinite] bg-gradient-to-r from-transparent to-transparent isolate via-gray-400/20"
          [ngClass]="{
            'rounded-md': rounding === SkeletonLoaderRounding.ROUNDED_MD,
            'rounded-lg': rounding === SkeletonLoaderRounding.ROUNDED_LG
          }"
        ></div>
      </div>
    </ng-container>
  }
</div>

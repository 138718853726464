import { Component, ElementRef, inject, ViewChild } from "@angular/core";
import { AccountService } from 'src/app/common/services/account.service';
import { PortalService } from 'src/app/common/services/portal.service';
import { BillingService } from "../../services/billing.service";
import { RegistrationState } from "../../models/registration-state.enum";

@Component({
  selector: 'app-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss'],
})
export class SidebarLayoutComponent {
  @ViewChild('scrollableContainer', { static: true }) scrollableContainer: ElementRef<HTMLDivElement>;

  private _portalService = inject(PortalService);
  private _accountService = inject(AccountService);
  private _billingService = inject(BillingService);

  readonly clusters$ = this._portalService.getClusterList();
  readonly user = this._accountService.user;

  readonly billing$ = this._billingService.getBilling();
  readonly RegistrationState = RegistrationState;

  onLogout(): void {
    this._accountService.logout();
  }
}

import { Component, inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Organization } from "../../common/models/organization";
import { AccountService } from "../../common/services/account.service";
import {
  AddPaymentWizardDialogComponent
} from "../../common/dialogs/add-payment-wizard-dialog/add-payment-wizard-dialog.component";
import { SkeletonLoaderRounding } from "../../common/components/skeleton-loader/skeleton-loader.component";
import { RegistrationState } from "../../common/models/registration-state.enum";

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss'],
})
export class UpgradeComponent implements OnInit, OnDestroy {
  readonly SkeletonLoaderRounding = SkeletonLoaderRounding;
  readonly RegistrationState = RegistrationState;

  private terminator$ = new Subject<void>();
  private refreshCardDetails$ = new Subject<void>();

  loading: boolean = false;
  initialLoad: boolean = true;
  organization: Organization;

  private _accountService = inject(AccountService);
  private _matDialog = inject(MatDialog);
  protected _router = inject(Router);

  constructor() {}

  ngOnInit(): void {
    this.loadOrganization();
  }

  loadOrganization(): void {
    this.loading = true;

    this._accountService.getOrganization().subscribe((organization) => {
      this.organization = organization;
      this.loading = false;
      this.initialLoad = false;
    });
  }

  ngOnDestroy(): void {
    this.terminator$.next();
    this.terminator$.complete();
    this.refreshCardDetails$.complete();
  }

  onAddPaymentMethod(): void {
    const dialogRef = this._matDialog.open(AddPaymentWizardDialogComponent, {
      // See `mat-dialog.scss`
      panelClass: 'dialog-full-screen',
    });

    dialogRef.afterClosed().subscribe((wizardCompleted) => {
      if (!wizardCompleted) {
        console.error('Closed add payment dialog before payment method was added.');
        return;
      }
      this._router.navigate(['/']);
    });
  }
}

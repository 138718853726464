import { Component, CUSTOM_ELEMENTS_SCHEMA, DestroyRef, HostListener, inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { filter, map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { interval, Subscription } from "rxjs";
import { ClusterStatus } from "../../../common/models/cluster";
import { SpinnerService } from "../../../common/services/spinner.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-start-cluster',
  templateUrl: './wake-cluster.component.html',
  styleUrls: ['./wake-cluster.component.scss'],
})
export class WakeClusterComponent implements OnInit {
  private _route = inject(ActivatedRoute);
  private _http = inject(HttpClient);
  private _spinnerService = inject(SpinnerService);
  private _destroyRef = inject(DestroyRef);

  starting: boolean;
  clusterStatus: Subscription;
  clusterName: string;
  error: any;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {
    // We only start after receiving mouse input to prevent unintended cluster wake-ups (either in browser tabs or by robots)
    if (this.starting) {
      return;
    }

    this._spinnerService.show('Your cluster is starting - this process may take up to 5 minutes. Billing only starts once it is running.');
    this.starting = true;
    this.wakeCluster()?.subscribe(() => {
        this.clusterStatus = interval(5000).subscribe((x => {
          this.checkStatus().subscribe((status: string) => {
            if (status === ClusterStatus[ClusterStatus.RUNNING]) {
              this.clusterStatus.unsubscribe();
              this.getClusterUri().pipe(takeUntilDestroyed(this._destroyRef)).subscribe((clusterUri: string) => {
                if (!clusterUri.includes('://')) {
                  clusterUri = 'https://' + clusterUri;
                }
                window.location.href = clusterUri;
              })
            } else if (status !== ClusterStatus[ClusterStatus.STOPPED] && status !== ClusterStatus[ClusterStatus.STARTING]) {
              console.error('waking cluster', `cluster ${this.clusterName} is in error state`);
              this._spinnerService.hide();
              this.error = {errorMessage: "Cluster failed to start. This is a technical error and our team was automatically notified. Contact us on support@exense.ch for urgent support."};
              this.clusterStatus.unsubscribe();
            }
          });
        }));
      }, (error) => {
        this.error = error.error || error;
        console.error('Error when waking up cluster', error)
        this._spinnerService.hide();
      }
    )
  }

  ngOnInit(): void {
    this.getClusterName().pipe(takeUntilDestroyed(this._destroyRef)).subscribe(clusterName => {
      this.clusterName = clusterName;
    });
  }

  private getClusterName() {
    return this._route.queryParams.pipe(
      filter(params => !!params.clusterName),
      map((params) => params.clusterName),
    )
  }

  private getClusterUri() {
    return this._route.queryParams.pipe(
      filter(params => !!params.clusterUri),
      map((params) => params.clusterUri),
    )
  }

  private wakeCluster() {
    if (!this.clusterName) {
      return;
    }

    return this._http.post(`${environment.apiUrl}/cluster/${this.clusterName}/wake`,
      {},
      {headers: new HttpHeaders({'Content-Type': 'application/json'})});
  }

  private checkStatus() {
    return this._http.get<string>(`${environment.apiUrl}/cluster/${this.clusterName}/status`,
      {responseType: 'text' as 'json'});
  }

}

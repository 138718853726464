import { Component, ElementRef, inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef } from '@angular/material/dialog';
import { BillingAddressStepComponent } from 'src/app/common/components/billing-address-step/billing-address-step.component';
import { SwiperContainer } from 'swiper/swiper-element';

const BILLING_ADDRESS_STEP = 0;
const BILLING_DETAILS_STEP = 1;

@Component({
  selector: 'app-add-payment-wizard-dialog',
  templateUrl: './add-payment-wizard-dialog.component.html',
  styleUrls: ['./add-payment-wizard-dialog.component.scss'],
})
export class AddPaymentWizardDialogComponent implements OnInit {
  @ViewChild('swiperContainer', { static: true }) swiperContainer: ElementRef<SwiperContainer>;
  @ViewChild(BillingAddressStepComponent) billingAddressStepComponent: BillingAddressStepComponent;

  private _matDialogRef = inject(MatDialogRef<AddPaymentWizardDialogComponent>);

  readonly billingAddressStep = BILLING_ADDRESS_STEP;
  readonly billingDetailsStep = BILLING_DETAILS_STEP;

  activeStep: number = BILLING_ADDRESS_STEP;
  billingAddressComplete: boolean = false;
  billingDetailsComplete: boolean = false;
  paginationTransition: boolean = false;

  ngOnInit(): void {
    // Disable manual swiping
    this.swiperContainer.nativeElement.swiper.disable();
  }

  setActiveStep(activeStep: number): void {
    // Disallow step navigation if billing details step hasn't been completed
    if (!this.billingAddressComplete) {
      // Trigger required fields indication
      this.billingAddressStepComponent.addressSettingsComponent.form.nativeElement.requestSubmit();
      return;
    }

    this.paginationTransition = true;
    this.activeStep = activeStep;
    this.swiperContainer.nativeElement.swiper.enable();
    this.swiperContainer.nativeElement.swiper.slideTo(activeStep);
    this.swiperContainer.nativeElement.swiper.disable();
  }

  onSwiperContainerClick(): void {
    // Apparently there is a problem when clicking outside of Stripe Element inputs while having them wrapped in a `swiper-container`
    if (document.activeElement instanceof HTMLIFrameElement) {
      document.activeElement.blur();
    }
  }

  onDetailsStepComplete(): void {
    this.billingDetailsComplete = true;
    this._matDialogRef.close(true);
  }

  onAddressStepComplete(): void {
    this.billingAddressComplete = true;
    this.setActiveStep(BILLING_DETAILS_STEP);
  }
}

import { Component, inject } from "@angular/core";
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonPadding, ButtonStyle, ButtonType } from 'src/app/common/components/button/button.component';

@Component({
  selector: 'app-close-account-dialog',
  templateUrl: './close-account-dialog.component.html',
  styleUrls: ['./close-account-dialog.component.scss'],
})
export class CloseAccountDialogComponent {
  readonly _matDialogRef = inject(MatDialogRef<CloseAccountDialogComponent>);
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;
}

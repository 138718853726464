<div class="flex items-start gap-5">
  <div class="flex-1 p-4 border border-gray-200 rounded-lg flex items-start gap-3">
    <div class="w-12 h-8 bg-white border border-gray-100 rounded-md px-2 py-1">
      <img [src]="plugin.imgSrc" [alt]="plugin.imgAlt" class="w-full h-full" />
    </div>

    <div class="flex-1 text-sm leading-sm">
      <div class="font-medium text-gray-700">{{ plugin.title }}</div>
      <div class="text-gray-500 pb-2">{{ plugin.description }}</div>

      <button
        type="button"
        (click)="pluginRemove.emit(plugin.type)"
        class="font-medium text-gray-500 focus:outline-none focus:text-primary-400"
      >
        Remove
      </button>
    </div>
  </div>

  @if (customerPrices ?? {}; as customerPrices) {
    @if ((customerPrices[plugin.type].amount / 100) * billingPeriod.minutes
    | currency : '' : '' : billingPeriod.decimalPlaces; as amount) {
      <app-costs [amount]="amount" [unit]="billingPeriod.unit"/>
    }
  }
</div>

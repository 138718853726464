<h3 class="text-lg leading-lg font-medium text-gray-900 pb-1">Organization address</h3>

<p class="text-sm leading-sm text-gray-500">The address of your organization</p>

<div class="pt-4 pb-6">
  <hr />
</div>

<app-address-settings
  [countries]="countries"
  submitButtonTitle="Update organization address"
  [initialFormValue]="initialFormValue"
  [withPhoneNumber]="true"
  [loading]="loading"
  (formSubmit)="onSubmit($event)"
/>

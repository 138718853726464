export const environment = {
  production: false,
  simulate: false,
  apiUrl: '/rest',
  version: '{BUILD_VERSION}',
  gTagId: 'G-EQ8J35LR7E'
};

// alternative API URL approach if we want to let docker set the desired API:
// https://stackoverflow.com/questions/47426721/angular-cli-change-rest-api-url-on-build/65322383#65322383

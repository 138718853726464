import { inject, Injectable } from "@angular/core";
import { interval, Observable, ReplaySubject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

interface Dictionary {
  [index: string]: Subscription;
}

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  protected _http = inject(HttpClient);
  protected cache: {
    [key: string]: {
      subject?: ReplaySubject<any>;
      observable?: Observable<any>;
      poll?: Subscription;
      value?: any;
    };
  } = {};
  private intervalSubscriptions = {} as Dictionary;

  createIntervalSubscription(name: string, pollingFunction: any, _interval = 15000) {
    // FIXME: we need a better (and saver) way to handle interval subscriptions as to prevent overflows
    if (this.intervalSubscriptions[name]) {
      this.intervalSubscriptions[name].unsubscribe();
    }

    this.intervalSubscriptions[name] = interval(_interval).subscribe(pollingFunction);
  }

  cancelAllIntervals() {
    for (let key in this.intervalSubscriptions) {
      this.intervalSubscriptions[key].unsubscribe();
    }
  }
}

import { Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, skip, startWith, tap } from 'rxjs';
import { RequestAgentQuotaDialogData } from 'src/app/common/dialogs/request-agent-quota-dialog/request-agent-quota-dialog-data.interface';
import { RequestAgentQuotaDialogComponent } from 'src/app/common/dialogs/request-agent-quota-dialog/request-agent-quota-dialog.component';
import { BillingPeriod, CustomerPrices } from 'src/app/common/models/billing';
import { QuotaKey } from 'src/app/common/models/quota';
import { AGENTS, Agent, AgentType } from 'src/app/common/services/agents.token';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

export type AgentsControlValue = {
  [key in AgentType]?: number;
};

const DEFAULT_AGENT_COUNT = 1;

@Component({
  selector: 'app-agents-configuration',
  templateUrl: './agents-configuration.component.html',
  styleUrls: ['./agents-configuration.component.scss'],
})
export class AgentsConfigurationComponent implements OnInit, OnChanges {
  @Input() billingPeriod!: BillingPeriod;
  @Input() customerPrices?: CustomerPrices;
  @Input() agentsControl = new UntypedFormControl({});
  @Input() maxQuota!: number;

  private _matDialog = inject(MatDialog);
  _agents = inject(AGENTS);

  private _destroyRef = inject(DestroyRef);

  protected agentByType: Partial<Record<AgentType, Agent>> = {};
  protected sliderControlByAgentType: Partial<Record<AgentType, UntypedFormControl>> = {};

  readonly activeByAgentType: Partial<Record<AgentType, boolean>> = {};
  readonly remainingQuotaByAgentType: Partial<Record<AgentType, number>> = {};
  readonly AgentType = AgentType;

  agentTypes: AgentType[] = [];
  allAgentsAdded: boolean = false;
  totalUsedQuota: number = 0;

  ngOnInit(): void {
    this.agentByType = this._agents.reduce((acc: any, agent) => {
      acc[agent.type] = agent;
      return acc;
    }, {});

    this.sliderControlByAgentType = this._agents.reduce((acc: any, agent) => {
      acc[agent.type] = new UntypedFormControl(
        this.agentsControl.value?.[agent.type] ?? DEFAULT_AGENT_COUNT
      );
      return acc;
    }, {});

    const sliderControlsValuesChanges = Object.entries(this.sliderControlByAgentType).map(
      ([agentType, sliderControl]) =>
        sliderControl.valueChanges.pipe(
          startWith(0),
          tap(() => this.updateRemainingQuotas(agentType as AgentType))
        )
    );

    combineLatest(sliderControlsValuesChanges)
      .pipe(skip(1), takeUntilDestroyed(this._destroyRef))
      .subscribe(() => {
        this.updateAgentsControl();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.maxQuotaChanges(changes);
    this.customerPricesChanges(changes);
  }

  onRequestQuota(): void {
    this._matDialog.open<RequestAgentQuotaDialogComponent, RequestAgentQuotaDialogData>(
      RequestAgentQuotaDialogComponent,
      {
        data: {
          quotaKey: QuotaKey.AGENTS_PER_CLUSTER,
        },
      }
    );
  }

  onAddAgent(agentType: AgentType): void {
    const totalUsedQuota = this.calculateTotalUsedQuota();

    if (totalUsedQuota >= this.maxQuota) {
      this.onRequestQuota();
      return;
    }

    this.activeByAgentType[agentType] = true;
    this.totalUsedQuota++;
    this.updateAgentTypes();
    this.updateAllAgentsAdded();
    this.updateAgentsControl();
    this.updateRemainingQuotas();
  }

  onRemoveAgent(agentType: AgentType): void {
    delete this.activeByAgentType[agentType];
    this.totalUsedQuota--;
    this.updateAgentTypes();
    this.updateAllAgentsAdded();
    this.resetSliderControl(agentType);
    this.updateAgentsControl();
    this.updateRemainingQuotas();
  }

  private maxQuotaChanges(changes: SimpleChanges): void {
    if (!changes['maxQuota']) {
      return;
    }

    if (!changes['maxQuota'].firstChange) {
      return;
    }

    this._agents.forEach((agent) => {
      this.remainingQuotaByAgentType[agent.type] = this.maxQuota;
    });
  }

  private customerPricesChanges(changes: SimpleChanges): void {
    if (!changes['customerPrices']) {
      return;
    }

    if (this.customerPrices) {
      this._agents.forEach((agent) => {
        if (this.agentsControl.value?.[agent.type]) {
          this.activeByAgentType[agent.type] = true;
          this.totalUsedQuota++;
          this.updateAgentTypes();
          this.updateAllAgentsAdded();
        }
      });
    }
  }

  private updateAgentTypes(): void {
    this.agentTypes = Object.keys(this.activeByAgentType) as AgentType[];
  }

  private updateAgentsControl(): void {
    const agentsControlValue: AgentsControlValue = {
      [AgentType.JAVA_UI_AUTOMATION]: 0,
      [AgentType.DOTNET_UI_AUTOMATION]: 0,
    };

    this.agentTypes.forEach((agentType) => {
      agentsControlValue[agentType] = this.sliderControlByAgentType[agentType]!.value;
    });

    this.agentsControl.setValue(agentsControlValue);
  }

  private updateAllAgentsAdded(): void {
    this.allAgentsAdded = this._agents.every((agent) => this.activeByAgentType[agent.type]);
  }

  private resetSliderControl(agentType: AgentType): void {
    this.sliderControlByAgentType[agentType]!.setValue(DEFAULT_AGENT_COUNT);
  }

  private calculateTotalUsedQuota(): number {
    return Object.entries(this.sliderControlByAgentType)
      .filter(([agentType]) => this.agentTypes.includes(agentType as AgentType))
      .reduce((total, [, control]) => total + control.value, 0);
  }

  private updateRemainingQuotas(skipAgentType?: AgentType): void {
    this.totalUsedQuota = this.calculateTotalUsedQuota();

    this.agentTypes.forEach((agentType) => {
      if (agentType === skipAgentType) {
        return;
      }

      this.remainingQuotaByAgentType[agentType] =
        this.maxQuota - (this.totalUsedQuota - this.sliderControlByAgentType[agentType]!.value);
    });
  }
}

import { Component, DestroyRef, inject, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddPaymentWizardDialogComponent } from 'src/app/common/dialogs/add-payment-wizard-dialog/add-payment-wizard-dialog.component';
import { RegistrationState } from 'src/app/common/models/registration-state.enum';
import { AccountService } from 'src/app/common/services/account.service';
import { SidebarLayoutComponent } from '../sidebar-layout/sidebar-layout.component';
import { PaymentCardSettingsComponent } from './payment-card-settings/payment-card-settings.component';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  PaymentsDismissibleNoticeComponent
} from "../dismissible-notice/payments-dismissible-notice/payments-dismissible-notice.component";
import {
  StripeDismissibleNoticeComponent
} from "../dismissible-notice/stripe-dismissible-notice/stripe-dismissible-notice.component";
import { TabComponent } from "../tabset/tab/tab.component";
import {
  OrganizationAccountSettingsComponent
} from "./organization-account-settings/organization-account-settings.component";
import {
  OrganizationMembersSettingsComponent
} from "./organization-members-settings/organization-members-settings.component";
import {
  OrganizationAddressSettingsComponent
} from "./organization-address-settings/organization-address-settings.component";
import {
  UserChangePasswordSettingsComponent
} from "./user-change-password-settings/user-change-password-settings.component";
import { UserPersonalInfoSettingsComponent } from "./user-personal-info-settings/user-personal-info-settings.component";
import { TabsetComponent } from "../tabset/tabset.component";
import { MatInputModule } from "@angular/material/input";
import { MatFormField, MatFormFieldModule } from "@angular/material/form-field";

export enum SettingsTabs {
  USER_SETTINGS,
  MANAGE_ORGANIZATION,
  PAYMENT_METHOD,
}

export const SETTINGS_DEFAULT_ACTIVE_TAB_INDEX = SettingsTabs.USER_SETTINGS;
export const SETTINGS_ACTIVE_TAB_QUERY_PARAM = 'activeTab';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  @ViewChild(PaymentCardSettingsComponent) paymentCardSettingsComponent: PaymentCardSettingsComponent;
  // @ViewChild(PaymentAddressSettingsComponent) paymentAddressSettingsComponent: PaymentAddressSettingsComponent;

  private _router = inject(Router);
  private _route = inject(ActivatedRoute);
  private _sidebarLayoutComponent = inject(SidebarLayoutComponent);
  private _accountService = inject(AccountService);
  private _matDialog = inject(MatDialog);

  private _destroyRef = inject(DestroyRef);

  activeTabIndex: number = SETTINGS_DEFAULT_ACTIVE_TAB_INDEX;

  ngOnInit() {
    this._route.queryParams.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((queryParams) => {
      const activeTabQueryParam = queryParams[SETTINGS_ACTIVE_TAB_QUERY_PARAM];

      // Determine active tab index, if none fallback to default
      this.activeTabIndex = activeTabQueryParam ? +activeTabQueryParam : SETTINGS_DEFAULT_ACTIVE_TAB_INDEX;

      // Reset scrolling position (UX)
      this._sidebarLayoutComponent.scrollableContainer.nativeElement.scrollTop = 0;

      // No need to navigate in case `activeTabQueryParam` as we are already there
      if (!activeTabQueryParam) {
        this.navigateTo(this.activeTabIndex);
      }
    });
  }

  onActiveTabIndexChange(activeTabIndex: number): void {
    // Show add payment wizard if there is no payment method set up
    if (
      activeTabIndex === SettingsTabs.PAYMENT_METHOD &&
      this._accountService.organization()?.registrationState !== RegistrationState.BILLABLE
    ) {
      const dialogRef = this._matDialog.open(AddPaymentWizardDialogComponent, {
        // See `mat-dialog.scss`
        panelClass: 'dialog-full-screen',
      });

      dialogRef.afterClosed().subscribe((wizardCompleted) => {
        if (!wizardCompleted) {
          return;
        }

        this.navigateTo(activeTabIndex);
        // Trigger refresh manually for payment settings components
        this.paymentCardSettingsComponent.ngOnInit();
        // this.paymentAddressSettingsComponent.ngOnInit();
      });

      return;
    }

    this.navigateTo(activeTabIndex);
  }

  private navigateTo(activeTabIndex: number): void {
    this._router.navigate([], {
      queryParams: {
        [SETTINGS_ACTIVE_TAB_QUERY_PARAM]: activeTabIndex,
      },
      replaceUrl: this._router.url === '/settings',
    });
  }
}

@if (active) {
  <div
    role="alert"
    [@zoomInDownOnEnter]
    [@zoomOutDownOnLeave]
    class="max-w-xs md:max-w-lg lg:max-w-2xl p-4 mb-4 bg-white rounded-lg drop-shadow-lg shadow-black/5 border border-gray-100 flex flex-col gap-4"
  >
    <div class="flex items-center gap-3">
      @switch (toastPackage.toastType) {
        @case ('toast-success') {
          <div class="inline-flex items-center justify-center w-8 h-8 text-success-500 bg-success-100 rounded-lg">
            <app-svg-icon name="check-circle"/>
          </div>
        }

        @case ('toast-warning') {
          <div class="inline-flex items-center justify-center w-8 h-8 text-warning-500 bg-warning-100 rounded-lg">
            <app-svg-icon name="info-circle"/>
          </div>
        }

        @case ('toast-error') {
          <div class="inline-flex items-center justify-center w-8 h-8 text-error-500 bg-error-100 rounded-lg">
            <app-svg-icon name="x-circle"/>
          </div>
        }
      }

      <h4 class="flex-1 text-gray-700">{{ title }}</h4>

      <button
        type="button"
        class="bg-white text-gray-400 rounded-lg p-1.5 inline-flex items-center justify-center h-8 w-8 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:text-gray-600 focus:bg-gray-100"
        (click)="onClose()"
      >
        <app-svg-icon name="x-close" class="text-[0.625rem]"/>
      </button>
    </div>

    <p class="flex-1 text-gray-500">{{ message }}</p>

    @if (!options.disableTimeOut) {
      <div class="bg-gray-200 rounded-full h-1.5">
        <div class="bg-primary-600 h-1.5 rounded-full animate-[progressFullToZero_4.5s_linear_forwards]"></div>
      </div>
    }
  </div>
}

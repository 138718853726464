<app-card-brand [cardBrand]="cardDetails?.brand"/>

<div class="flex-1 text-sm leading-sm">
  <p class="text-gray-700 font-medium">
    {{ cardDetails ? cardTitleByCardBrand[cardDetails.brand] : null }} ending in {{ cardDetails?.last4 }}
  </p>

  <p class="text-gray-500">Expiry {{ cardDetails?.expMonth }}/{{ cardDetails?.expYear }}</p>
</div>

<app-button
  [buttonType]="ButtonType.BUTTON"
  [buttonStyle]="ButtonStyle.BTN_1"
  [buttonPadding]="ButtonPadding.BTN_PADDING_0"
  (buttonClick)="editClick.emit()"
>
  Edit
</app-button>

import { Component, OnInit } from '@angular/core';
import { zoomInDownOnEnterAnimation, zoomOutDownOnLeaveAnimation } from 'angular-animations';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { SvgIconComponent } from "../svg-icon/svg-icon.component";

const ANIMATIONS_DURATION = 500;
const TOASTR_DURATION = 5000;

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    zoomInDownOnEnterAnimation({ duration: ANIMATIONS_DURATION }),
    zoomOutDownOnLeaveAnimation({ duration: ANIMATIONS_DURATION }),
  ],
})
export class ToastComponent extends Toast implements OnInit {
  active = false;

  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit() {
    this.active = true;
    if (this.options.disableTimeOut) {
      return;
    }

    setTimeout(() => {
        this.active = false;
    }, TOASTR_DURATION - ANIMATIONS_DURATION);
  }

  onClose(): void {
    this.active = false;

    setTimeout(() => {
      this.remove();
    }, ANIMATIONS_DURATION);
  }
}

<div class="center">
  @if (!error) {
    @if (!starting) {
    <p>Waiting for mouse movement before starting {{clusterName}}...</p>
    <p>Mouse movement is required to prevent unintended cluster wakeup - if you can read this text the mouse recognition failed."</p>
    } @else {
      <p>Starting {{clusterName}} -  in progress</p>
    }
  } @else {
    @if (error.errorMessage) {
    <p class="error">{{ error.errorMessage }}</p>
    }

    @if (error.errorName) {
    <a href="/login">Login to Step Portal to fix issue</a>
    }
  }
</div>

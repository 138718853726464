@if (organization?.registrationState !==  RegistrationState.BILLABLE) {
  <div class=" mx-auto bg-white p-6 h-full">
    <h1 class="text-xl font-bold text-primary-900 mb-4">Thank You for Trying Step!</h1>
    <p class="mb-6 text-primary-700 text-sm">
      We hope the evaluation period has demonstrated the power and flexibility of Step. Now, it's time to unlock even more potential by upgrading to our <strong>Flexible</strong> plan.
    </p>

    <h2 class="text-l font-semibold text-primary-900 mb-3">With the Flexible Plan, you’ll enjoy:</h2>
    <ul class="list-disc list-inside mb-6 text-primary-700 text-sm">
      <li><strong>Pay only for what you use:</strong> Flexible billing of controller and agent usage.</li>
      <li><strong>Scale up to 100 concurrent agents</strong> per cluster, with the flexibility to request up to 1000 agents to meet your unique needs.</li>
      <li><strong>Create up to 5 clusters:</strong> Scale your setup as needed to handle multiple projects.</li>
    </ul>

    <h2 class="text-l font-semibold text-primary-900 mb-3">Pricing Details:</h2>
    <ul class="list-disc list-inside mb-6 text-primary-700 text-sm">
      <li><strong>Controller Usage:</strong> $0.32 per hour while the Step controller is running. The controller must be active to create plans, run executions, and manage configurations.</li>
      <li><strong>Agent Usage:</strong> $0.20 per hour per agent. Agent hours are calculated as the product of the number of agents and the duration they are in use:
        <code class="bg-gray-100 text-gray-800 px-1 py-0.5 rounded">Agent Hours = (# of agents) × (duration of test in hours)</code>
      </li>
    </ul>

    <p class="mb-8 text-primary-700 text-sm">
      Upgrading is simple—just add your billing details and start enjoying the full power of Step!
    </p>

    <div class="text-center">
      <a (click)="onAddPaymentMethod()" class="mat-ripple border rounded-lg text-sm leading-sm bg-primary-600 border-transparent text-white focus-ring-primary-1 py-2 px-3 button">
        Upgrade Now
      </a>
    </div>

    <p class="text-gray-500 text-sm mt-4 italic absolute bottom-2">
      Note: Any remaining evaluation credits will not transfer to the Flexible plan.
    </p>
  </div>
} @else {
  <div class=" mx-auto bg-white p-6">
   <h1 class="text-xl font-bold text-green-700 mb-4">You’re Already Upgraded!</h1>
      <p class="text-gray-700 mb-6">
        Thank you for upgrading to the <strong>Flexible</strong> plan! You now have access to:
      </p>
      <ul class="list-disc list-inside text-gray-700 mb-6">
        <li>Create up to <strong>5 clusters</strong> to handle complex workloads.</li>
        <li>Scale up to <strong>100 agents per cluster</strong>.</li>
        <li>Retain unused clusters for up to <strong>180 days</strong>, providing peace of mind.</li>
        <li>Flexible, pay-as-you-go pricing to suit your unique use case.</li>
      </ul>
      <p class="text-gray-700">
        Explore all that Step has to offer and take your workflows to the next level!
      </p>
  </div>
}

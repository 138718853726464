<div class="p-4 border border-gray-200 rounded-lg flex items-center gap-3">
  <div class="w-12 h-8 bg-white border border-gray-100 rounded-md px-2 py-1">
    <img [src]="plugin.imgSrc" [alt]="plugin.imgAlt" class="w-full h-full" />
  </div>

  <div class="text-sm leading-sm font-medium text-gray-700 flex items-center gap-1">
    {{ plugin.title }}
    <app-svg-icon name="help-circle" [matTooltip]="plugin.description" class="text-gray-400"/>
  </div>
</div>

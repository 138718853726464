import { Component, inject, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import { AccountService } from '../../../common/services/account.service';

const EMAIL_VERIFICATION_SUCCESS = 'E-mail verification successful';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
  private _route = inject(ActivatedRoute);
  private _router = inject(Router);
  private _accountService = inject(AccountService);
  private _toastrService = inject(ToastrService);
  private _spinnerService = inject(SpinnerService);

  ngOnInit(): void {
    const { id } = this._route.snapshot.params;

    this._spinnerService.show();

    this._accountService.verifyMail(id).subscribe({
      next: () => {
        this._spinnerService.hide();
        this._toastrService.success(EMAIL_VERIFICATION_SUCCESS);
        this._router.navigate(['/login']);
      },
      error: () => {
        this._spinnerService.hide();
      },
    });
  }
}

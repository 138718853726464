import { inject, Injectable } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from "rxjs/operators";
import { AddPaymentDialogComponent } from '../dialogs/add-payment-dialog/add-payment-dialog.component';
import { AddPaymentWizardDialogComponent } from '../dialogs/add-payment-wizard-dialog/add-payment-wizard-dialog.component';
import { RegistrationState } from '../models/registration-state.enum';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentGuard  {
  private _matDialog = inject(MatDialog);
  private _accountService = inject(AccountService);

  canActivate(): Observable<boolean> {
    return this._accountService.getOrganization().pipe(
      switchMap((organization) => {
        if (organization?.registrationState === RegistrationState.BILLABLE) {
          return of(true);
        }

         if (organization?.registrationState === RegistrationState.EVALUATION) {
          return of(true);
        }

        // Open the first dialog
        const addPaymentDialogRef = this._matDialog.open(AddPaymentDialogComponent, {
          disableClose: true,
        });

        return addPaymentDialogRef.afterClosed().pipe(
          switchMap((addPaymentMethod) => {
            if (!addPaymentMethod) {
              return of(false);
            }

            // Open the payment wizard dialog
            const dialogRef = this._matDialog.open(AddPaymentWizardDialogComponent, {
              panelClass: 'dialog-full-screen',
            });

            return dialogRef.afterClosed().pipe(
              map((wizardCompleted) => wizardCompleted ?? false)
            );
          })
        );
      }),
      catchError((error) => {
        console.error('Error fetching organization state:', error);
        return of(false);
      })
    );
  }
}

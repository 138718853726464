  import { Pipe, PipeTransform } from '@angular/core';
  import { AbstractControl } from "@angular/forms";

  @Pipe({
    name: 'hasError',
    pure: false
  })
  export class HasErrorPipe implements PipeTransform {
    transform(control: AbstractControl, error: string): any {
      return control.hasError(error);
    }
  }

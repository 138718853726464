import { Component, Input } from '@angular/core';
import { Plugin } from 'src/app/common/services/plugins.token';
import { SvgIconComponent } from "../../../common/components/svg-icon/svg-icon.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
  selector: 'app-plugin-details',
  templateUrl: './plugin-details.component.html',
  styleUrls: ['./plugin-details.component.scss'],
})
export class PluginDetailsComponent {
  @Input() plugin!: Plugin;
}

<h3 class="text-lg leading-lg font-medium text-gray-900 pb-1">Step 2</h3>

<p class="text-sm leading-sm text-gray-500">Add your credit card</p>

<div class="pt-5">
  <hr />
</div>

<app-card-settings
  submitButtonTitle="Create billable account"
  (formSubmit)="onSubmit($event)"
  class="block py-12"
/>

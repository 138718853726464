<app-dialog-header icon="help-circle" (dialogClose)="onCancel()"/>

<app-dialog-body>
  <ng-template #heading>
    @if (_dialogData.headingTemplate; as headingTemplate) {
      <ng-container
        *ngTemplateOutlet="
          headingTemplate;
          context: _dialogData.headingContext ?? {}
        "
      ></ng-container>
    }
  </ng-template>

  <ng-template #description>
    @if (_dialogData.descriptionTemplate) {
      <ng-container
        *ngTemplateOutlet="
          _dialogData.descriptionTemplate;
          context: _dialogData.descriptionContext ? _dialogData.descriptionContext : {}
        "
      ></ng-container>
    }
  </ng-template>

  <div class="flex justify-center gap-3">
    <app-button
      [buttonType]="ButtonType.BUTTON"
      [buttonStyle]="ButtonStyle.BTN_1"
      [buttonPadding]="ButtonPadding.BTN_PADDING_0"
      (buttonClick)="onCancel()"
    >
      Cancel
    </app-button>

    <app-button
      [buttonType]="ButtonType.BUTTON"
      [buttonStyle]="ButtonStyle.BTN_0"
      [buttonPadding]="ButtonPadding.BTN_PADDING_0"
      (buttonClick)="onContinue()"
    >
      Continue
    </app-button>
  </div>
</app-dialog-body>

import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AbstractControl, ReactiveFormsModule, UntypedFormBuilder, Validators } from "@angular/forms";
import {
  StripeCardExpiryElementOptions,
  StripeCardNumberElement,
  StripeCardNumberElementOptions,
  StripeElementChangeEvent,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { NgxStripeModule, StripeCardNumberComponent, StripeFactoryService, StripeInstance } from "ngx-stripe";
import { map } from 'rxjs';
import { AccountService } from 'src/app/common/services/account.service';
import { BillingService } from 'src/app/common/services/billing.service';
import { ButtonComponent, ButtonPadding, ButtonStyle, ButtonType } from "../button/button.component";
import { StripeCardInputWrapperComponent } from "../stripe-card-input-wrapper/stripe-card-input-wrapper.component";
import { MatError, MatFormField, MatFormFieldModule } from "@angular/material/form-field";
import { LabelComponent } from "../label/label.component";
import { AsyncPipe } from "@angular/common";
import { MatInputModule } from "@angular/material/input";
import { HasErrorPipe } from "../../pipes/has-error.pipe";

export interface CardSettingsFormValue {
  cardName: string;
  cardNumberElement: StripeCardNumberElement;
}

export interface CardSettingsFormSubmitParams {
  formValue: CardSettingsFormValue;
  stripeInstance: StripeInstance;
}

const STRIPE_CARD_COMMON_OPTIONS = {
  classes: {
    base: 'border border-gray-300 rounded-md py-2 px-3 group-data-invalid:border-error-500',
  },
  style: {
    base: {
      color: '#111921',
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '20px',
      '::placeholder': {
        color: '#b5c5d8',
      },
    },
    invalid: {
      iconColor: '#f04438',
      color: '#f04438',
      '::placeholder': {
        color: '#f04438',
      },
    },
  },
};

@Component({
  selector: 'app-card-settings',
  templateUrl: './card-settings.component.html',
  styleUrls: ['./card-settings.component.scss'],
})
export class CardSettingsComponent implements OnInit {
  @Input() submitButtonTitle!: string;
  @Input() withCancelButton?: boolean;

  @Output() cancelClick = new EventEmitter<void>();
  @Output() formSubmit = new EventEmitter<CardSettingsFormSubmitParams>();

  @ViewChild(StripeCardNumberComponent) stripeCardNumber: StripeCardNumberComponent;

  private _formBuilder = inject(UntypedFormBuilder);
  private _accountService = inject(AccountService);
  private _billingService = inject(BillingService);
  private _stripeFactory = inject(StripeFactoryService);

  readonly elementsOptions: StripeElementsOptions = {
    locale: 'en',
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css?family=Inter',
      },
    ],
  };
  readonly cardExpiryOptions: StripeCardExpiryElementOptions = {
    ...STRIPE_CARD_COMMON_OPTIONS,
  };
  readonly cardNumberOptions: StripeCardNumberElementOptions = {
    showIcon: true,
    ...STRIPE_CARD_COMMON_OPTIONS,
  };
  readonly cardCVCOptions: StripeCardExpiryElementOptions = {
    ...STRIPE_CARD_COMMON_OPTIONS,
  };
  readonly formGroup = this._formBuilder.group({
    cardName: ['', Validators.required],
    cardExpiry: [''],
    cardNumber: [''],
    cardCSC: [''],
  });
  readonly requiredErrorMessageByControl = new Map<AbstractControl, string>([
    [this.formGroup.controls.cardExpiry, 'Expiry is required'],
    [this.formGroup.controls.cardNumber, 'Card number is required'],
    [this.formGroup.controls.cardCSC, 'CVC is required'],
  ]);
  readonly emptyByControl = new Map<AbstractControl, boolean>([
    [this.formGroup.controls.cardExpiry, true],
    [this.formGroup.controls.cardNumber, true],
    [this.formGroup.controls.cardCSC, true],
  ]);
  readonly stripe$ = this._billingService.getStripeKey().pipe(
    map(({ publishableKey }) => {
      return this._stripeFactory.create(publishableKey);
    })
  );
  readonly ButtonType = ButtonType;
  readonly ButtonStyle = ButtonStyle;
  readonly ButtonPadding = ButtonPadding;

  ngOnInit() {
    // Prefill with user full name
    this.formGroup.controls.cardName.setValue(this._accountService.user()?.fullName);
  }

  onBlur(control: AbstractControl): void {
    control.markAsTouched();

    const required = this.emptyByControl.get(control) ? this.requiredErrorMessageByControl.get(control) : null;

    if (required) {
      control.setErrors({
        required,
      });
    } else {
      control.setErrors(null);
    }
  }

  onChange(control: AbstractControl, event: StripeElementChangeEvent): void {
    // Preserve empty state change in order to be used in `onBlur`
    this.emptyByControl.set(control, event.empty);

    const required = event.empty ? this.requiredErrorMessageByControl.get(control) : null;

    if (event.error) {
      control.setErrors({
        stripeValidation: event.error.message,
        required,
      });
    } else if (required) {
      control.setErrors({
        required,
      });
    } else {
      control.setErrors(null);
    }
  }

  onSubmit(stripeInstance: StripeInstance): void {
    const stripeControls: AbstractControl[] = [
      this.formGroup.controls.cardExpiry,
      this.formGroup.controls.cardNumber,
      this.formGroup.controls.cardCSC,
    ];

    // Manually trigger blur as Stripe Elements, unlike `mat-form-field`, are not affected implicitly when submit occurs
    stripeControls.forEach((stripeControl) => {
      this.onBlur(stripeControl);
    });

    if (this.formGroup.invalid) {
      return;
    }

    this.formSubmit.emit({
      formValue: {
        cardName: this.formGroup.controls.cardName.value,
        cardNumberElement: this.stripeCardNumber.element,
      },
      stripeInstance,
    });
  }
}

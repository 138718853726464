import { NgModule } from '@angular/core';
import { provideRouter, RouterModule, Routes, withRouterConfig } from "@angular/router";
import { UpgradeComponent } from "./pages/upgrade/upgrade.component";
import { AuthGuard } from './common/services/auth-guard.service';
import { PaymentGuard } from './common/services/payment-guard.service';
import { UnauthGuard } from './common/services/unauth-guard.service';
import { LoginComponent } from './pages/account/login/login.component';
import { PasswordResetComponent } from './pages/account/password-reset/password-reset.component';
import { RegistrationComponent } from './pages/account/registration/registration.component';
import { VerifyComponent } from './pages/account/verify/verify.component';
import { BillingComponent } from './pages/billing/billing.component';
import { InvoiceDetailsComponent } from './pages/billing/invoice-details/invoice-details.component';
import { ClusterDetailsComponent } from './pages/cluster/cluster-details/cluster-details.component';
import { ClusterListComponent } from './pages/cluster/cluster-list.component';
import { CreateClusterComponent } from './pages/cluster/create-cluster/create-cluster.component';
import { SettingsComponent } from './common/components/settings/settings.component';
import { SidebarLayoutComponent } from './common/components/sidebar-layout/sidebar-layout.component';
import { WakeClusterComponent } from "./pages/cluster/wake-cluster/wake-cluster.component";
import { MaintenanceComponent } from "./pages/error-pages/maintenance/maintenance.component";

const routes: Routes = [
  {
    path: '',
    component: SidebarLayoutComponent,
    canActivate: [AuthGuard],
    data: { title: 'Sidebar' },
    children: [
      {
        path: '',
        redirectTo: 'cluster',
        pathMatch: 'full'
      },
      {
        path: 'settings',
        component: SettingsComponent,
        data: { title: 'Settings' },
      },
      {
        path: 'upgrade',
        component: UpgradeComponent,
        data: { title: 'Upgrade' },
      },
      {
        path: 'billing',
        component: BillingComponent,
        data: { title: 'Billing' },
      },
      {
        path: 'billing/invoice/:id',
        component: InvoiceDetailsComponent,
        data: { title: 'Invoice' },
      },
      {
        path: 'cluster',
        component: ClusterListComponent,
        data: { title: 'Cluster' },
      },
      {
        path: 'cluster/details/:id',
        component: ClusterDetailsComponent,
        data: { forceNewInstance: true, title: 'Cluster Details'}
      },
      {
        path: 'cluster/create-cluster',
        component: CreateClusterComponent,
        canActivate: [PaymentGuard],
        data: { title: 'Create Cluster' },
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [UnauthGuard],
    data: { title: 'Login Page' },
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    canActivate: [UnauthGuard],
    data: { title: 'Registration Page' },
  },
  {
    path: 'verify/:id',
    component: VerifyComponent,
    data: { title: 'Verify' },
  },
  {
    path: 'password/reset/:id',
    component: PasswordResetComponent,
    data: { title: 'Password Reset' },
  },
  {
    path: 'cluster/wake',
    component: WakeClusterComponent,
    data: { title: 'Cluster Wake' },
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    data: { title: 'Maintenance' },
  },
  {
    path: '**',
    canActivate: [AuthGuard],
    component: LoginComponent,
    data: { title: 'Login' },
  },
];

@NgModule({
  imports: [RouterModule],
  exports: [RouterModule],
  providers: [
    provideRouter(routes, withRouterConfig({
      onSameUrlNavigation: 'reload',
    })),
  ],
})
export class AppRoutingModule {}
